import { useMutation } from '@apollo/client';
import { DELETE_INVENTORY_OIL_ITEM } from '../util/gql';
import Modal from './Modal';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import styles from '../sass/components/ErrorModal.module.scss';

interface DeleteInventoryOilModalProps {
  itemId: string;
  itemName: string;
  oilWeight: string;
  isOpen: boolean;
  onClose: () => void;
  onDeleteSuccess: () => void;
  setErrorModal: (status: boolean) => void;
}

export default function DeleteInventoryPartModal({
  itemId,
  itemName,
  oilWeight,
  isOpen,
  onClose,
  onDeleteSuccess,
  setErrorModal,
}: DeleteInventoryOilModalProps) {
  const [deleteInventoryOilItem] = useMutation(DELETE_INVENTORY_OIL_ITEM, {
    variables: { id: parseInt(itemId, 10) },
    onCompleted: () => {
      onDeleteSuccess();
      onClose();
    },
    onError: () => {
      onClose();
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const handleDelete = async () => {
    await deleteInventoryOilItem();
  };

  return (
    <Modal open={isOpen} onClose={onClose} disableBackdropClick>
      <div className={styles.modal}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>Delete Oil from inventory</h4>
          <p>
            {`Are you sure you want to delete ${itemName} ${oilWeight}?`}
          </p>
          <div className={styles.buttonsContainer}>
            <Button onClick={onClose} variant="tertiary">Cancel</Button>
            <Button onClick={handleDelete} variant="primary">Delete</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
