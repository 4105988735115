import SettingsIcon from '@mui/icons-material/Settings';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import useAuthentication from '../hooks/useAuthentication';
import styles from '../sass/components/Account.module.scss';
import AccountSettings from './AccountSettings';

export default function AdminPanel() {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentFranchise = useAppSelector((state) => state.franchise.name);
  const isInventory = useAppSelector((state) => state.franchise.inventory);
  const { handleLogin, loggedIn } = useAuthentication();

  const isManager = currentUser.roles.includes('manager');
  const isTechnician = currentUser.roles.includes('technician');
  const isTester = currentUser.roles.includes('tester');

  return (
    <>
      <div className={styles.accountPage}>
        <div className={styles.header}>
          <div className={styles.leftHeader}>
            <h1 className={styles.headerDesktop}>
              Hello,
              {' '}
              {currentUser.name}
              {currentFranchise ? ` - ${currentFranchise}` : ''}
            </h1>
            <h4 className={styles.headerMobile}>
              Hello,
              {' '}
              {currentUser.name}
            </h4>
          </div>
          <div className={styles.rightHeader}>
            <Dropdown
              tetherComponent={<SettingsIcon />}
              id="settings-dropdown"
              className={styles.mobileHeaderActions}
            >
              <div className={styles.dropdownItem}>
                <button
                  onClick={handleLogin}
                  type="button"
                  className={styles.dropdownButton}
                >
                  <p>Logout</p>
                </button>
              </div>
            </Dropdown>
            <div className={styles.desktopHeaderActions}>
              <Button
                variant="secondary"
                onClick={handleLogin}
              >
                {loggedIn ? 'Log Out' : 'Log In'}
              </Button>
            </div>
          </div>
        </div>
        <AccountSettings
          isManager={isManager}
          isTechnician={isTechnician}
          isTester={isTester}
          isInventory={isInventory}
        >
          <Outlet />
        </AccountSettings>
      </div>
    </>
  );
}
