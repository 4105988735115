import { Vehicle } from './Vehicle';

export enum ServiceType {
  addOn = 'ADD_ON',
  oilChange = 'OIL_CHANGE'
}

type service = {
  id: number
  serviceType: {
    name: string
  }
}

export type LineItem = {
  id: number
  price: number
  tax: number
  quantity: number
  name: string
  description: string
  service: service
  serviceId?: number
  discountedPrice?: number
}

export type WorkOrderDiscountCode = {
  amountApplied: number
  discountCodeId: string
}

export type ServiceItem = {
  id: number
  price: number
  tax: number
  quantity: number
  name: string
  description: string
  service?: service
  discountedPrice?: number
  serviceId?: number
}

export type InventoryPartItem = {
  id: number
  quantity: number
  partName: string
  partType: string
}

export type Notes = {
  notes: string
}

export type PoNumber = {
  poNumber: string
}

export enum WorkOrderStatus {
  assigned = 'ASSIGNED',
  confirmed = 'CONFIRMED',
  complete = 'COMPLETE',
}

export enum SubscriptionStatus {
  active = 'ACTIVE',
  pending = 'PENDING',
  cancelled = 'CANCELLED',
}

export interface VehicleSubscription {
  id: number
  subscriptionTypeId: number
  status: SubscriptionStatus
}

export interface WorkOrder {
  id: number
  scheduledAt: Date
  completedAt?: Date
  isInspected?: boolean
  isConfirmed?: boolean
  poNumber?: string
  user: {
    id: number
    email: string
    name: string
    discountAmount: string
    discountType: string
  }
  address: {
    lat: number;
    lng: number;
    city: string
    province: string
    provinceCode: string
    street: string
    countryCode: string
    postalCode: string
  }
  contact: {
    email: string;
    name: string;
    phoneNumber: string;
  }
  franchise: {
    id: number;
    name: string;
    managerEmail: string;
    phoneNumber: string;
  }
  userVehicle: {
    id: number
    vin: string
    name: string
    subscriptions?: [VehicleSubscription]
  }
  lineItems: [LineItem]
  vehicle: Vehicle
  notes: string
  status: WorkOrderStatus
  unavailabilityReason: string
  cancelReason: string
  proofOfCancelLocation: string
  invoice: {
    status: string
    billedAmount: number
    id: number
  }
  van: {
    id: number
    name: string
    userVans: Array<{
      user: {
        name?: string;
        email: string;
      };
    }>;
  }
  workOrderDiscountCode: WorkOrderDiscountCode,
}

export interface GroupedWorkOrders {
  [key: string]: WorkOrder[]
}

export interface Appointment {
  id: number
  vehicle: Vehicle
  lineItems: [LineItem]
  scheduledAt: Date
}

export interface WorkOrderProps {
  id: number
  user: string
  userId: number,
  userDiscountType: string,
  userDiscountAmount: string,
  address: string
  addressLat: number
  addressLng: number
  contactName: string
  contactEmail: string
  contactPhone: string
  vehicle: string
  workOrderDiscountCode: WorkOrderDiscountCode
  oilWeight: string
  oilVolume?: number
  recommendedOilId?: number
  vehicleId: number
  userVehicleId: number
  vin?: string
  name?:string
  franchise: string
  franchiseId: number
  vanId?: number
  vanName?: string
  technicianName?: string
  technicianEmail?: string
  scheduledAt: string
  notes: string
  cancelReason: string
  proofOfCancelLocation: string
  status: WorkOrderStatus
  invoiceStatus: string
  billedAmount: string
  invoiceId: number
  services: Array<string>
  lineitems: Array<LineItem>
  unavailabilityReason?: string
  vehicleSubscription?: VehicleSubscription
  activeSubscription?: VehicleSubscription
  poNumber?: string
  engine?: string
  year: string
  model: string
  make: string
}

export interface WorkOrderReason {
  workOrder: WorkOrder,
  reason: string,
}

export interface Franchise {
  id: number;
  name: string;
}

export type MileageEventType = 'OIL_CHANGE' | 'USER'
