import { useState } from 'react';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import DecreaseInventoryToolModal from './DecreaseInventoryToolModal';

interface DecreaseInventoryToolContainerProps {
  vanToolId: string;
  toolName: string;
  onClose: () => void;
  onSubmit: (data: {
    reason: string; }) => void;
}

export default function DecreaseInventoryToolContainer({
  vanToolId,
  toolName,
  onClose,
  onSubmit,
}: DecreaseInventoryToolContainerProps) {
  const [reason, setReason] = useState<string>('');
  const handleSubmit = () => {
    onSubmit({
      reason,
    });
    onClose();
  };

  return (
    <div className={styles.container}>
      <DecreaseInventoryToolModal
        toolName={toolName}
        vanToolId={vanToolId}
        reason={reason}
        setReason={setReason}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
