import { useMemo } from 'react';
import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import TextInput from './TextInput';
import { IncreaseInventoryToolModalProps } from '../interfaces/Inventory';

export default function IncreaseInventoryModal({
  toolName,
  quantity,
  setQuantity,
  onClose,
  onSubmit,
}: IncreaseInventoryToolModalProps) {
  const handleQuantityChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const value = Number(target.value);
    setQuantity(value);
  };

  const isConfirmDisabled = useMemo(() => quantity <= 0, [quantity]);

  const handleConfirm = () => {
    onSubmit({ quantity });
  };
  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Increase Inventory Quantity</h4>

        <p className={styles.partName}>
          {toolName}
        </p>

        <p className="label">Total Quantity Increased</p>
        <TextInput
          type="number"
          min={1}
          placeholder="Enter total quantity"
          value={quantity.toString()}
          onChange={handleQuantityChange}
          isRequired
        />

        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} variant="tertiary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="primary"
            inactive={isConfirmDisabled}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
