import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { VALIDATE_DISCOUNT_CODE } from '../util/gql';
import Button from './Button';
import TextInput from './TextInput';
import styles from '../sass/components/Invoice.module.scss';

interface DiscountCodeInputProps {
  discountCode?: string;
  setDiscountCode: (discountCode: string) => void;
  selectedServices: {
    value: number;
    id: number;
  }[];
  carId: number | null;
  userId: number;
  email?: string;
  onDiscountApplied: (discountAmount: number, discountCodeId?: string) => void;
  setErrorMessage: (message: string) => void;
  errorMessage?: string;
}

export default function DiscountCodeInput({
  discountCode,
  setDiscountCode,
  selectedServices,
  carId,
  userId,
  email,
  onDiscountApplied,
  errorMessage,
  setErrorMessage,
}: DiscountCodeInputProps) {
  const [applyDiscountCode, {
    data, loading, error,
  }] = useLazyQuery(VALIDATE_DISCOUNT_CODE);

  const handleApplyDiscount = () => {
    if (discountCode?.trim()) {
      applyDiscountCode({
        variables: {
          code: discountCode,
          services: selectedServices.map((service) => ({
            id: service.id,
            value: Math.round(service.value * 100),
          })),
          carId,
          userId,
          email,
        },
        fetchPolicy: 'network-only',
      });
    } else {
      setErrorMessage('Please enter a valid discount code');
    }
  };

  useEffect(() => {
    if (data?.validateDiscountCode?.valid) {
      const { discountAmount } = data.validateDiscountCode;
      onDiscountApplied(discountAmount,
        data.validateDiscountCode.discountCodeId);
      setErrorMessage('');
    } else if (data && !data.validateDiscountCode?.valid) {
      setErrorMessage(data.validateDiscountCode?.message
        || 'Invalid discount code');
    } else if (error) {
      setErrorMessage('Error validating discount code');
    }
  }, [data, error, onDiscountApplied, setErrorMessage]);

  return (
    <div key="discount-code">
      <span className="label">Add Discount / Groupon Code:</span>
      <div className={styles.cardContent}>
        <div className={styles.discountInputWrapper}>
          <TextInput
            value={discountCode}
            onChange={(e) => setDiscountCode(e.currentTarget.value)}
            placeholder="Enter discount code"
            className={styles.discountInput}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleApplyDiscount();
              }
            }}
          />
          <Button
            className={styles.applyDiscountButton}
            onClick={handleApplyDiscount}
            inactive={loading}
          >
            Apply
          </Button>
        </div>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>
    </div>
  );
}
