import Popper from '@mui/material/Popper';
import { format, parseISO } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getTimeValue } from '../components/AppointmentDatePicker';
import Button from '../components/Button';
import Invoice from '../components/Invoice';
import useAuthentication from '../hooks/useAuthentication';
import { ReactComponent as Calendar } from '../images/calendar.svg';
import { ReactComponent as Clock } from '../images/clock.svg';
import { ReactComponent as Location } from '../images/location.svg';
import styles from '../sass/components/Success.module.scss';
import { ROUTE_ACCOUNT_ROOT, ROUTE_ROOT } from '../util/constants';
import {
  selectPotentialFranchiseId,
  setPotentialFranchiseId,
  selectVanId,
  setStoredDiscountAmount,
} from '../features/booking/bookingSlice';

export default function Success() {
  const dispatch = useAppDispatch();
  const [anchor, setAnchor] = useState<null | HTMLDivElement>(null);
  const [mapLoading, setMapLoading] = useState(true);
  const [currentImageSrc, setCurrentImageSrc] = useState('');
  const { loggedIn } = useAuthentication();
  const anchorEl = useRef<null | HTMLDivElement>(null);
  const name = useAppSelector((state) => state.serviceLocation.contact.name);
  const dateTime = useAppSelector((state) => state.serviceLocation.dateTime);

  const serviceAddress = useAppSelector(
    (state) => state.serviceLocation.serviceAddress,
  );
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const customer = useAppSelector((state) => state.customer);
  const navigate = useNavigate();
  const potentialFranchiseId = useAppSelector(selectPotentialFranchiseId);
  const potentialVanId = useAppSelector(selectVanId);

  useEffect(() => {
    setAnchor(anchorEl.current);
  }, []);

  useEffect(() => {
    const loadingImage = new Image();
    loadingImage.src = 'https://maps.googleapis.com/maps/api/staticmap'
      + '?size=600x600&scale=1&format=png&maptype=roadmap'
      + '&markers=size:normal'
      + '%7Ccolor:red%7C'
      + `${serviceAddress?.lat},`
      + `${serviceAddress?.lng}`
      + `&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    loadingImage.onload = () => {
      setMapLoading(false);
      setCurrentImageSrc(loadingImage.src);
    };
  });

  const open = Boolean(anchor);
  const appointmentDate = dateTime
    ? format(parseISO(dateTime.date), 'MMMM dd, yyyy')
    : null;
  const appointmentTime = dateTime ? getTimeValue(dateTime) : '';

  const smallAppointmentDate = dateTime
    ? format(parseISO(dateTime.date), 'MM/dd/yyyy')
    : null;
  const smallAppointmentTime = dateTime
    ? format(parseISO(dateTime.date), 'HH:mm')
    : null;

  const newAppointment = () => {
    dispatch(setPotentialFranchiseId(null));
    dispatch(setStoredDiscountAmount(null));
    navigate(ROUTE_ROOT);
    window.location.reload();
  };

  const goHome = () => {
    dispatch(setPotentialFranchiseId(null));
    dispatch(setStoredDiscountAmount(null));
    navigate(ROUTE_ROOT);
  };

  const goToAccount = () => {
    dispatch(setPotentialFranchiseId(null));
    dispatch(setStoredDiscountAmount(null));
    navigate(ROUTE_ACCOUNT_ROOT);
  };

  return (
    <div className={styles.success}>
      {(potentialFranchiseId) && (
        <div className={styles.header}>
          <h1>
            {potentialVanId
              ? 'Service Confirmed'
              : 'Service Requested'}
          </h1>
        </div>
      )}
      {(!potentialFranchiseId) && (
        <div className={styles.header}>
          <h1>Outside Service Area</h1>
        </div>
      )}
      <div className={styles.body}>
        {(potentialFranchiseId) && (
          <div className={styles.bookingInfoSection}>
            {(currentUser.roles?.includes('manager')
          || currentUser.roles?.includes('technician')
          || currentUser.roles?.includes('tester')) ? (
            <>
              <h4>
                {potentialVanId
                  ? 'Service confirmed for'
                  : 'Service requested for'}
                {' '}
                {customer.name}
              </h4>
              <p>
                {customer.name}
                &apos;s
                {' '}
                service is scheduled for
                <span className={styles.date}>
                  {' '}
                  {appointmentDate}
                  {' '}
                  at
                  {' '}
                  {appointmentTime}
                </span>
                .
                A confirmation email has been sent to the provided email
                address. If the customer has an account with Go Oil, the
                booking will be added to their account.
                <br />
                <br />
                You can reschedule or cancel your appointment through
                the appointment details section in your account.
                Alternatively, feel free to reach out to us via phone
                {' '}
                <a href="tel:1-833-466-4520">1-833-466-4520</a>
                {' '}
                or email
                {' '}
                <a href="mailto:hello@gooil.ca">hello@gooil.ca</a>
              </p>

            </>
              ) : (
                <>
                  <h4>
                    Thank you,
                    {' '}
                    {name}
                    !
                  </h4>
                  <p>
                    We have received your booking request for
                    {' '}
                    <span className={styles.date}>
                      {appointmentDate}
                      {' '}
                      at
                      {' '}
                      {appointmentTime}
                    </span>
                    .
                    We will be in touch with you shortly to
                    confirm your appointment.
                    <br />
                    <br />
                    You can reschedule or cancel your appointment through
                    the appointment details section in your account.
                    Alternatively, feel free to reach out to us via phone
                    {' '}
                    <a href="tel:1-833-466-4520">1-833-466-4520</a>
                    {' '}
                    or email
                    {' '}
                    <a href="mailto:hello@gooil.ca">hello@gooil.ca</a>
                  </p>

                </>
              )}
            <h4 className={styles.subheader}>Requested Service</h4>
            <Invoice
              className={styles.invoice}
              showDisclaimer={false}
              condense
            />
            {(currentUser.roles?.includes('manager')
          || currentUser.roles?.includes('technician')
          || currentUser.roles?.includes('tester')) ? (
            <>
              <p>
                Payment can be made by card or using
                the &quot;Pay Later&quot; option on the day of service.
              </p>
              <p>
                <span>
                  The quote is subject to change if vehicle
                  information is not provided in full.
                </span>
              </p>
            </>

              ) : (
                <>
                  <p>
                    Payment can be made by card or using
                    the &quot;Pay Later&quot; option on the day of service.
                  </p>
                  <p>
                    <span>
                      <span className="label">
                        Your total cost may be higher.&nbsp;
                      </span>
                      Without full vehicle information,
                      we are only able to provide a baseline quote.
                      <br />
                      <span>
                        Tax is not included in the price.
                      </span>
                    </span>
                  </p>

                </>
              )}
          </div>
        )}
        {(!potentialFranchiseId) && (
          <div className={styles.bookingInfoSection}>
            {(currentUser.roles?.includes('manager')
          || currentUser.roles?.includes('technician')
          || currentUser.roles?.includes('tester')) ? (
            <>
              <h4>
                Service requested for
                {' '}
                {customer.name}
              </h4>
              <p>
                {customer.name}
                &apos;s
                {' '}
                service is scheduled for
                <span className={styles.date}>
                  {' '}
                  {appointmentDate}
                  {' '}
                  at
                  {' '}
                  {appointmentTime}
                </span>
                .
                It appears that the booking could not be completed because the
                location is outside of the service area.
                Please log into the admin panel or contact
                Go Oil head office for assistance with this issue.
                <br />
                <br />
                Feel free to reach out to us via phone
                {' '}
                <a href="tel:1-833-466-4520">1-833-466-4520</a>
                {' '}
                or email
                {' '}
                <a href="mailto:hello@gooil.ca">support@gooil.ca</a>
              </p>

            </>
              ) : (
                <>
                  <h4>
                    Thank you,
                    {' '}
                    {name}
                    !
                  </h4>
                  <p>
                    We have received your booking request for
                    {' '}
                    <span className={styles.date}>
                      {appointmentDate}
                      {' '}
                      at
                      {' '}
                      {appointmentTime}
                    </span>
                    .
                    Unfortunately, it appears that your location
                    is outside of our service area.
                    However, we are expanding fast and look
                    forward to servicing you soon!
                    <br />
                    Please feel free to reach out if you have any questions.
                    {' '}
                    <a href="tel:1-833-466-4520">1-833-466-4520</a>
                    {' '}
                    or
                    {' '}
                    <a href="mailto:hello@gooil.ca">hello@gooil.ca</a>
                    {' '}
                    for further assistance.
                  </p>

                </>
              )}
            <h4 className={styles.subheader}>Requested Service</h4>
            <Invoice
              className={styles.invoice}
              showDisclaimer={false}
              condense
            />
            {(currentUser.roles?.includes('manager')
          || currentUser.roles?.includes('technician')
          || currentUser.roles?.includes('tester')) ? (
            <>
              <p>
                Payment can be made by card or using
                the &quot;Pay Later&quot; option on the day of service.
              </p>
              <p>
                <span>
                  The quote is subject to change if vehicle
                  information is not provided in full.
                </span>
              </p>
            </>

              ) : (
                <>
                  <p>
                    Payment can be made by card or using
                    the &quot;Pay Later&quot; option on the day of service.
                  </p>
                  <p>
                    <span>
                      <span className="label">
                        Your total cost may be higher.&nbsp;
                      </span>
                      Without full vehicle information,
                      we are only able to provide a baseline quote.
                      <br />
                      <span>
                        Tax is not included in the price.
                      </span>
                    </span>
                  </p>

                </>
              )}
          </div>
        )}

        <div className={styles.mapSection}>
          <img
            src={currentImageSrc}
            alt="google maps of your address"
            className={styles.map}
          />
          <div className={styles.secretDiv} ref={anchorEl} />
          {!mapLoading && (
            <Popper
              open={open}
              anchorEl={anchor}
              placement="top"
              modifiers={[
                {
                  name: 'flip',
                  enabled: true,
                  options: {
                    altBoundary: true,
                    rootBoundary: 'document',
                    padding: 8,
                    marginBottom: '1rem',
                  },
                },
                {
                  name: 'preventOverflow',
                  enabled: false,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: 'document',
                    padding: 8,
                    marginBottom: '1rem',
                  },
                },
              ]}
            >
              <div className={styles.popper}>
                <p className={styles.popperHeader}>Go Oil Booking</p>
                <div className={styles.popperSection}>
                  <Location className={styles.icon} />
                  <p>{serviceAddress?.street}</p>
                </div>
                <div className={styles.popperSection}>
                  <Calendar className={styles.icon} />
                  <p>{smallAppointmentDate}</p>
                </div>
                <div className={styles.popperSection}>
                  <Clock className={styles.icon} />
                  <p>{smallAppointmentTime}</p>
                </div>
              </div>
            </Popper>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        { loggedIn ? (
          <>
            <Button
              onClick={goHome}
              variant="tertiary"
            >
              Book Another Appointment
            </Button>
            <Button
              onClick={goToAccount}
              variant="primary"
            >
              My Account
            </Button>
          </>
        ) : (
          <Button
            onClick={newAppointment}
            variant="primary"
          >
            New Appointment
          </Button>
        )}
      </div>
    </div>
  );
}
