/* eslint-disable max-len */
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import useAuthentication from '../hooks/useAuthentication';
import {
  GroupedWorkOrders,
  WorkOrder,
  WorkOrderReason,
} from '../interfaces/WorkOrder';
import styles from '../sass/components/WorkOrderAppointments.module.scss';
import { weekdayDateString } from '../util/formatDate';
import {
  GET_FRANCHISEE_WORK_ORDERS,
} from '../util/gql';
import Button from './Button';
import EmptyList from './EmptyList';
import TextInput from './TextInput';
import { ReactComponent as Search } from '../images/search.svg';
import WorkOrderList from './WorkOrderList';

interface WorkOrderAppointmentsProps {
  workOrderStatus: string
  emptyListMsg: string
  isConfirmed?: boolean
  vanId?: number | null
}

export default function WorkOrderAppointments(
  {
    workOrderStatus,
    emptyListMsg,
    isConfirmed,
    vanId,
  }: WorkOrderAppointmentsProps,
) {
  const [workOrders, setWorkOrders] = useState<GroupedWorkOrders>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [currentCount, setCurrentCount] = useState<number>(0);

  const [filter, setFilter] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');

  const { loggedIn: isLoggedIn } = useAuthentication();
  const { franchiseId } = useAppSelector((state) => state.auth.currentUser);

  const [getWorkOrders, { loading }] = useLazyQuery(GET_FRANCHISEE_WORK_ORDERS, {
    onCompleted: (data) => {
      if (!data?.getFranchiseeWorkOrders) return;

      const workOrdersWithReason = data.getFranchiseeWorkOrders.workOrderWithReason;
      setCurrentCount(currentCount + workOrdersWithReason.length);
      setTotal(data.getFranchiseeWorkOrders.total);

      const franchiseeWorkOrders: WorkOrder[] = workOrdersWithReason.map(
        (item: WorkOrderReason) => ({
          ...item.workOrder,
          unavailabilityReason: item.reason,
        }),
      );

      const groupedByScheduledAt = franchiseeWorkOrders
        .reduce((acc, current) => {
          const scheduledDate = weekdayDateString(current.scheduledAt);
          acc[scheduledDate] = acc[scheduledDate] || [];
          acc[scheduledDate].push(current);
          return acc;
        }, {} as GroupedWorkOrders);
      const allCurrentDates = Object.keys(workOrders);
      const lastDate = allCurrentDates[allCurrentDates.length - 1];

      if (groupedByScheduledAt[lastDate]) {
        let i = 0;
        while (i < groupedByScheduledAt[lastDate].length) {
          workOrders[lastDate].push(groupedByScheduledAt[lastDate][i]);
          i += 1;
        }
        delete groupedByScheduledAt[lastDate];
      }

      setWorkOrders({ ...workOrders, ...groupedByScheduledAt });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setFilter('');
    setInputValue('');
    setWorkOrders({});
    setCurrentPage(1);
    setCurrentCount(0);
    if (isLoggedIn) {
      getWorkOrders({
        variables: {
          franchiseId,
          status: workOrderStatus,
          pageNumber: 1,
          filter: '',
          isConfirmed,
          vanId,
        },
      });
    }
  }, [workOrderStatus, isConfirmed, franchiseId, vanId]);

  useEffect(() => {
    if (isLoggedIn) {
      getWorkOrders({
        variables: {
          franchiseId,
          status: workOrderStatus,
          pageNumber: currentPage,
          isConfirmed,
          filter,
          vanId,
        },
      });
    }
  }, [isLoggedIn, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleSearch = () => {
    setWorkOrders({});
    setCurrentPage(1);
    setCurrentCount(0);
    setTotal(0);
    getWorkOrders({
      variables: {
        franchiseId,
        status: workOrderStatus,
        pageNumber: currentPage,
        isConfirmed,
        vanId,
        filter,
      },
    });
  };

  const content = Object.keys(workOrders).length > 0 ? (
    <>
      <WorkOrderList
        workOrders={workOrders}
      />
      {currentCount < total && (
        <div
          className={styles.loadMoreButtonContainer}
        >
          <Button
            variant="secondary"
            onClick={handleLoadMore}
            className={styles.loadMoreButton}
          >
            Load more
          </Button>
        </div>
      )}

    </>
  ) : (
    <EmptyList msg={emptyListMsg} />
  );

  return (
    <div className={styles.completedWorkOrders}>
      <p className={styles.subtitle}>
        Use the search bar to find Work Orders by order ID, customer name,
        email, VIN, vehicle name, personal identifier, or vehicle year, make,
        model.
      </p>
      <div className={styles.searchDiv}>
        <div className={styles.searchBar}>
          <div className={styles.magnifyingGlass}>
            <Search />
          </div>
          <TextInput
            placeholder="Search Work Orders"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.currentTarget.value);
              setFilter(e.currentTarget.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </div>
        <Button
          variant="primary"
          onClick={handleSearch}
          className={styles.searchButton}
        >
          Search
        </Button>
      </div>
      { loading ? <p className={styles.loading}>Loading...</p> : content}
    </div>
  );
}
