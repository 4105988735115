import { WorkOrder } from '../interfaces/WorkOrder';

export const calculateOilByCountry = (
  countryCode: string,
  oilVolume: number,
): number => {
  if (countryCode === 'US') {
    const adjustedVolume = oilVolume * 1.05669;
    return parseFloat(adjustedVolume.toFixed(2));
  }
  return oilVolume;
};

export const convertToLiters = (
  countryCode: string,
  quantity: number,
): number => {
  if (countryCode === 'US') {
    const adjustedQuantity = quantity / 1.05669;
    return parseFloat(adjustedQuantity.toFixed(4));
  }
  return quantity;
};

export const serviceName = (
  { vehicle, lineItems }: WorkOrder, code: string, countryCode: string,
) => lineItems
  .map((lineItem) => {
    if (lineItem.service?.serviceType.name === 'OIL_CHANGE') {
      const adjustedOilVolume = calculateOilByCountry(
        String(countryCode), vehicle.oilVolume,
      );
      return `${lineItem.name} - ${adjustedOilVolume}${code} of oil`;
    }
    return lineItem.name;
  });

export const extractOilType = (weight: string): string => {
  const openParenIndex = weight.indexOf('(');
  return openParenIndex !== -1
    ? weight.substring(0, openParenIndex).trim()
    : weight.trim();
};

export const getCorrectOilWeight = (workOrder: WorkOrder): string => {
  const oilType = workOrder.vehicle.recommendedOil.weight;
  const extractedOilType = extractOilType(oilType);
  const serviceOilChangeNames = serviceName(
    workOrder,
    workOrder.address.countryCode === 'CA' ? 'L' : 'QT',
    workOrder.address.countryCode,
  );

  if (serviceOilChangeNames.some(
    (name) => name.includes('Basic Oil Change'),
  )) {
    return `${extractedOilType} (C)`;
  }
  if (serviceOilChangeNames.some(
    (name) => name.includes('Premium Synthetic'),
  )) {
    return `${extractedOilType} (F)`;
  }
  if (serviceOilChangeNames.some(
    (name) => name.includes('European Synthetic'),
  )) {
    return `${extractedOilType} (E)`;
  }
  return oilType;
};
