import { useQuery } from '@apollo/client';
import { useState } from 'react';
import {
  CompleteWorkOrderModalProps,
  CompleteWorkOrderModals,
  completeWorkOrderModalsList,
} from '../interfaces/Components';
import { SubscriptionType } from '../interfaces/Subscription';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import { GET_USER_VEHICLE_LATEST_MILEAGE } from '../util/gql';
import InvoiceDiscount from './InvoiceDiscount';
import Loader from './Loader';
import ModalCloseButton from './ModalCloseButton';
import Subscription from './Subscription';

export default function CompleteWorkOrderModal(
  { workOrderDetails, onClose, onSubmit }: CompleteWorkOrderModalProps,
) {
  const [mileage, setMileage] = useState<number>();
  const [poNumber, setPO] = useState<string>('');
  const [discountNum, setDiscountNum] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string>('');
  const [discountCodeId, setDiscountCodeId] = useState<string | undefined>('');
  const [totalOriginal, setTotalOriginal] = useState<number>(0);

  const [
    selectedSubscription,
    setSelectedSubscription,
  ] = useState<SubscriptionType | undefined>();
  const [
    activeModal,
    setActiveModal,
  ] = useState<CompleteWorkOrderModals>('subscription');
  const { data, loading } = useQuery(GET_USER_VEHICLE_LATEST_MILEAGE, {
    variables: { userVehicleId: workOrderDetails?.userVehicleId },
    onCompleted: () => {
      if (data.getLatestMileageByUserVehicleId?.mileage) {
        setMileage(data.getLatestMileageByUserVehicleId.mileage);
      }
    },
    fetchPolicy: 'network-only',
  });

  const resetTotalOriginal = () => {
    let total = 0;
    workOrderDetails?.lineitems.forEach((item) => {
      if (item.discountedPrice !== null
        && item.discountedPrice !== undefined
        && Number.isFinite(item.discountedPrice)) {
        total += item.discountedPrice;
      } else total += item.price;
    });
    setTotalOriginal(total / 100);
  };

  const setInvoiceDiscountDetails = (
    type: string, num: number, codeId?: string,
  ) => {
    setDiscountType(type);
    setDiscountNum(num);
    setDiscountCodeId(codeId);
  };
  const setInvoicePODetails = (po: string) => {
    setPO(po);
  };

  const handleSubmit = () => {
    onSubmit(
      discountType,
      discountNum,
      selectedSubscription?.id,
      poNumber,
      discountCodeId,
    );
    onClose();
  };

  const handleChangeActiveModal = (
    modalName: CompleteWorkOrderModals,
  ) => setActiveModal(modalName);

  const handleModalContinue = () => {
    const currentModalIndex = completeWorkOrderModalsList.indexOf(activeModal);
    const nextModal = completeWorkOrderModalsList[currentModalIndex + 1];
    if (nextModal === 'invoice') resetTotalOriginal();
    handleChangeActiveModal(nextModal);
  };

  const handleModalBack = () => {
    const currentModalIndex = completeWorkOrderModalsList.indexOf(activeModal);
    const nextModal = completeWorkOrderModalsList[currentModalIndex - 1];
    handleChangeActiveModal(nextModal);
  };

  if (loading) {
    return (
      <div className={styles.empty}>
        <Loader />
        Loading
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <ModalCloseButton onClose={onClose} />
      {activeModal === 'subscription' && (
        <Subscription
          onClose={handleModalBack}
          onClick={handleModalContinue}
          setSelectedSubscription={setSelectedSubscription}
          selectedSubscription={selectedSubscription}
          vehicleSubscription={workOrderDetails?.vehicleSubscription}
        />
      )}
      {activeModal === 'invoice'
      && (
        <InvoiceDiscount
          workOrderDetails={workOrderDetails}
          setInvoiceDiscountDetails={setInvoiceDiscountDetails}
          totalOriginal={totalOriginal}
          onBack={handleModalBack}
          onClick={handleSubmit}
          selectedSubscription={selectedSubscription}
        />
      )}
    </div>
  );
}
