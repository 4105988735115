import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import AddPartModal from './AddPartModal';
import {
  GET_PARTS_BY_TYPE_AND_SEARCH,
  GET_PART_TYPES,
  ADD_PART_TO_INVENTORY,
  CREATE_AND_ADD_NEW_PART_TO_INVENTORY,
} from '../util/gql';

interface AddPartContainerProps {
  onClose: () => void;
  onError: (error: string) => void;
  franchiseId: string;
  vanId: number;
  refetchItems: () => void;
}

export default function AddPartContainer({
  onClose,
  onError,
  franchiseId,
  vanId,
  refetchItems,
}: AddPartContainerProps) {
  const [partTypes, setPartTypes] = useState([]);
  const [availableParts, setAvailableParts] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    loading: partTypesLoading,
  } = useQuery(GET_PART_TYPES, {
    onCompleted: (data) => setPartTypes(data.getPartTypes),
    onError: (err) => onError(`Failed to load part types: ${err.message}`),
  });

  const [
    getParts, { loading: partsLoading },
  ] = useLazyQuery(GET_PARTS_BY_TYPE_AND_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => setAvailableParts(data.getPartsByTypeAndSearch),
    onError: (err) => onError(`Failed to load parts: ${err.message}`),
  });

  const [addPartToInventory] = useMutation(ADD_PART_TO_INVENTORY, {
    onError: (err) => onError(`Failed to add part: ${err.message}`),
  });

  const [createNewPart] = useMutation(CREATE_AND_ADD_NEW_PART_TO_INVENTORY, {
    onError: (err) => onError(`Failed to create part: ${err.message}`),
  });

  const handleConfirm = async (
    data: {
      selectedPart: string;
      newPartName: string;
      selectedType: string,
      vehicleId: number },
  ) => {
    try {
      setLoading(true);
      if (data.selectedPart) {
        await addPartToInventory({
          variables: {
            franchiseId,
            vanId,
            partId: parseInt(data.selectedPart, 10),
          },
        });
      } else if (data.newPartName && data.selectedType) {
        await createNewPart({
          variables: {
            franchiseId,
            vanId,
            name: data.newPartName,
            partTypeId: parseInt(data.selectedType, 10),
            vehicleId: data.vehicleId,
          },
        });
      }
      refetchItems();
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        onError(`Failed to add part: ${error.message}`);
      } else {
        onError('Failed to add part due to an unknown error');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedType) {
      setLoading(true);
      getParts({
        variables: {
          franchiseId,
          vanId,
          partTypeId: parseInt(selectedType, 10),
          searchTerm: '',
        },
      });
      setLoading(false);
    }
  }, [selectedType, getParts, franchiseId, vanId]);

  return (
    <AddPartModal
      onClose={onClose}
      onConfirm={handleConfirm}
      partTypes={partTypes}
      availableParts={availableParts}
      setSelectedType={setSelectedType}
      loading={loading || partTypesLoading || partsLoading}
    />
  );
}
