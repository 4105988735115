import cx from 'classnames';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectAddOnTotal } from '../features/addOns/addOnsSlice';
import {
  setStoredDiscountAmount,
  selectStoredDiscountAmount,
} from '../features/booking/bookingSlice';
import {
  selectServicesTotal,
} from '../features/serviceTypes/serviceTypesSlice';
import styles from '../sass/components/Invoice.module.scss';
import DiscountCodeInput from './DiscountCodeInput';
import { discountedFeesNames } from '../constants/discountedFees';

interface InvoiceProps {
  className?: string;
  showDisclaimer?: boolean;
  condense?: boolean;
  onlyServices?: boolean;
  onDiscountUpdate?: (
    discountAmount: number,
    discountFeesAmount: number,
    discointCodeId?: string,
  ) => void;
  isVendor?: boolean;
  isGuest?: boolean;
  email?: string;
}

export default function Invoice({
  className,
  showDisclaimer = true,
  condense,
  onlyServices = false,
  onDiscountUpdate,
  isVendor = false,
  isGuest = false,
  email,
}: InvoiceProps) {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const carId = useAppSelector((state) => state.car.id);
  const customerId = useAppSelector((state) => state.customer.id);
  const userId = customerId || currentUser.id;
  const storedDiscountAmount = useAppSelector(
    selectStoredDiscountAmount,
  );
  const [discountCode, setDiscountCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [prevEmail, setPrevEmail] = useState(email);

  useEffect(() => {
    if (isVendor || isGuest || (email && email !== prevEmail)) {
      dispatch(setStoredDiscountAmount(null));
      if (onDiscountUpdate) {
        onDiscountUpdate(0, 0, undefined);
      }
    }
    if (email !== prevEmail) {
      setPrevEmail(email);
    }
  }, [isVendor, isGuest, email, dispatch, onDiscountUpdate, prevEmail]);

  const setServiceValue = (
    value: number, discountedValue?: number,
  ) => (
    discountedValue !== null
    && discountedValue !== undefined
    && Number.isFinite(discountedValue)
      ? discountedValue
      : value
  );

  const selectedServices = (useAppSelector((state) => (
    state.serviceTypes.services
  )))
    .map((selectedService) => ({
      ...selectedService,
      value: setServiceValue(
        selectedService.value,
        selectedService.discountedValue,
      ),
    }));

  const selectedServicesForDiscount = selectedServices
    .map((selectedService) => ({
      value: setServiceValue(
        selectedService.value,
        selectedService.discountedValue,
      ),
      id: selectedService.id,
    }));

  const selectedAddOns = (useAppSelector((state) => state.addOns.addOns) || [])
    .map((selectedAddOn) => ({
      ...selectedAddOn,
      value: setServiceValue(selectedAddOn.value,
        selectedAddOn.discountedValue),
    }));

  const additionalFeeNames = [
    'Mobile Service Fee',
    'Supplies and Shop Fee',
    'Environmental Handling Fee',
    'Priority Service Fee',
  ];

  const additionalFees = selectedAddOns.filter(
    (addOn) => additionalFeeNames.includes(addOn.name),
  );

  const separateAddOns = selectedAddOns.filter(
    (addOn) => !additionalFeeNames.includes(addOn.name),
  );

  const discountedFees = selectedAddOns.filter(
    (addOn) => discountedFeesNames.includes(addOn.name),
  );

  const serviceTotal = useAppSelector((
    state,
  ) => selectServicesTotal(state));

  const addOnTotal = useAppSelector((state) => selectAddOnTotal(state));

  const make = useAppSelector((state) => (
    state.car.make
  ));
  const model = useAppSelector((state) => (
    state.car.model
  ));
  const year = useAppSelector((state) => (
    state.car.year
  ));

  const discountOnFees = discountedFees.reduce(
    (total, fee) => total + fee.value, 0,
  );

  const totalDiscountAmount = discountAmount
    ? discountAmount + discountOnFees
    : 0;

  const totalAmount = (
    addOnTotal
    + serviceTotal
    - totalDiscountAmount
  ).toFixed(2);

  useEffect(() => {
    setDiscountAmount(storedDiscountAmount ?? 0);
  }, [storedDiscountAmount]);

  const handleDiscountApplied = (
    newDiscountAmount: number,
    discountCodeId?: string,
  ) => {
    const recalculatedDiscountAmount = newDiscountAmount / 100;
    setDiscountAmount(recalculatedDiscountAmount);
    dispatch(setStoredDiscountAmount(recalculatedDiscountAmount));
    if (onDiscountUpdate) {
      onDiscountUpdate(recalculatedDiscountAmount,
        discountOnFees,
        discountCodeId);
    }
  };
  useEffect(() => {
    const newDiscountAmount = storedDiscountAmount ?? 0;
    setDiscountAmount(newDiscountAmount);
  }, [storedDiscountAmount]);

  return (
    <div key="invoice" className={cx(styles.invoice, className)}>
      <div
        key="summary"
        className={cx(styles.summarySection, styles.card, {
          [styles.condenseSections]: condense,
        })}
      >
        { !!selectedServices.length && (
          <div key="services" className={styles.cardSection}>
            {!onlyServices
              && (
              <span
                className={cx('label', styles.cardHeader)}
              >
                Services:
              </span>
              )}
            <div className={cx('label', styles.cardContent)}>
              {
                selectedServices.map((service) => (
                  <div key={service.id} className={styles.lineItem}>
                    <p>
                      &bull;
                      &nbsp;
                      &nbsp;
                      {service.name}
                    </p>
                    <p>
                      $
                      {service.value.toFixed(2)}
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
        )}
        { !onlyServices && !!separateAddOns.length && (
          <div key="addons" className={styles.cardSection}>
            <span className={cx('label', styles.cardHeader)}>Add Ons:</span>
            <div className={cx('label', styles.cardContent)}>
              {
                separateAddOns.map((addOn) => (
                  <div key={addOn.name} className={styles.lineItem}>
                    <p>
                      &bull;
                      &nbsp;
                      &nbsp;
                      {addOn.name}
                    </p>
                    <p>
                      $
                      {addOn.value.toFixed(2)}
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
        )}
        { !onlyServices && !!additionalFees.length && (
          <div key="additional-fees" className={styles.cardSection}>
            <span className={cx('label', styles.cardHeader,
              styles.additionalFees)}
            >
              Additional Fees:
            </span>
            <div className={cx('label', styles.cardContent,
              styles.additionalFees)}
            >
              {additionalFees.map((addOn) => (
                <div key={addOn.name} className={styles.lineItem}>
                  <p>
                    &bull; &nbsp;
                    {' '}
                    {addOn.name}
                  </p>
                  <p>
                    $
                    {addOn.value.toFixed(2)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {!onlyServices ? (
        <div
          key="totals"
          className={cx(styles.summarySection, {
            [styles.condenseSections]: condense,
            [styles.excludeHeaders]: onlyServices,
          })}
        >
          {!discountAmount && !(isVendor || isGuest) && showDisclaimer && (
          <DiscountCodeInput
            discountCode={discountCode}
            setDiscountCode={setDiscountCode}
            selectedServices={selectedServicesForDiscount}
            carId={carId}
            userId={userId}
            email={email}
            onDiscountApplied={handleDiscountApplied}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          )}

          {!!discountAmount && (
          <div>
            <div
              key="groupon-discount"
              className={cx(styles.lineItem, styles.noPaddingLeft)}
            >
              <span className="label">Groupon Discount (on Services)</span>
              <span>
                - $
                {discountAmount.toFixed(2)}
              </span>
            </div>
            {discountOnFees > 0 && (
            <div
              key="groupon-discount-fees"
              className={cx(styles.lineItem, styles.noPaddingLeft)}
            >
              <span className="label">Groupon Discount (on Fees)</span>
              <span>
                - $
                {discountOnFees.toFixed(2)}
              </span>
            </div>
            )}
          </div>
          )}
          <div key="total" className={cx(styles.lineItem, styles.totalLine)}>
            <span className="label">Total</span>
            <span>
              {`$${totalAmount}`}
              { (!make || !model || !year) && '*' }
            </span>
          </div>
          {(!make || !model || !year) && showDisclaimer && (
          <div className={styles.lineItem}>
            <span>
              <span className="label">
                Your total cost may be higher.&nbsp;
              </span>
              Without full vehicle information,
              we are only able to provide a baseline quote.
              <span>
                Tax is not included in the price.
              </span>
            </span>
          </div>
          )}
        </div>
      ) : (
        <div key="hint" className={styles.summarySection}>
          <p>
            *Total calculated at the next step.
          </p>
        </div>
      )}
    </div>
  );
}
