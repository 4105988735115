import { useMutation } from '@apollo/client';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import Delete from '@mui/icons-material/Delete';
import { useState, FormEvent } from 'react';
import Modal from './Modal';
import { useAppSelector } from '../app/hooks';
import { Vehicle } from '../interfaces/Vehicle';
import styles from '../sass/components/TableVehicleRowView.module.scss';
import {
  FIND_ALL_USER_VEHICLES_BY_CURRENT_USER,
  UPDATE_USER_VEHICLE_VIN_WITH_ID,
  UPDATE_USER_VEHICLE_NAME_WITH_ID,
  IS_WORK_ORDER_ASSIGNED_TO_USER_VEHICLE,
} from '../util/gql';
import Button from './Button';
import ErrorModalContainer from './CompleteModal';
import MileageModal from './MileageModal';
import TextInput from './TextInput';
import { dayTimeString } from '../util/formatDate';
import SubscriptionContainer from './SubscriptionContainer';

export default function VehicleRowView(props: { data: Vehicle }) {
  const { data } = props;
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);

  const [mileageModal, setMileageModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [
    subscriptionModalOpen,
    setSubscriptionModalOpen,
  ] = useState<boolean>(false);

  const isManager = currentUser.roles?.includes('manager');
  const isTechnician = currentUser.roles?.includes('technician');
  const isTester = currentUser.roles?.includes('tester');

  const rowText = `${data.year} ${data.make} ${data.model}`;

  // eslint-disable-next-line max-len
  const [checkWorkOrder, { data: checkData }] = useMutation(IS_WORK_ORDER_ASSIGNED_TO_USER_VEHICLE);

  const [addVin] = useMutation(UPDATE_USER_VEHICLE_VIN_WITH_ID);
  const [addName] = useMutation(UPDATE_USER_VEHICLE_NAME_WITH_ID);

  const handleDelete = async () => {
    const result = await checkWorkOrder({
      variables: { id: data.userVehicleId },
      fetchPolicy: 'network-only',
      refetchQueries: [{ query: FIND_ALL_USER_VEHICLES_BY_CURRENT_USER }],
    });
    if (result.data.isUserVehicleAssignedToWorkOrder === true) {
      // eslint-disable-next-line max-len, no-alert
      alert('Could not delete vehicle because an assigned work order still exists.');
    }
  };
  const handleVin = () => {
    addVin({
      variables: { vin: data.vin, userVehicleId: data.userVehicleId },
      refetchQueries: [{ query: FIND_ALL_USER_VEHICLES_BY_CURRENT_USER }],
    });
  };
  const handleName = () => {
    addName({
      variables: { name: data.name, userVehicleId: data.userVehicleId },
      refetchQueries: [{ query: FIND_ALL_USER_VEHICLES_BY_CURRENT_USER }],
    });
  };

  const handleErrorCreatingMileage = () => {
    setMileageModal(false);
    setErrorModal(true);
  };

  const handleVinChange = (event: FormEvent<HTMLInputElement>) => {
    const newVin = event.currentTarget.value;
    data.vin = newVin;
  };

  const handleNameChange = (event: FormEvent<HTMLInputElement>) => {
    const newName = event.currentTarget.value;
    data.name = newName;
  };

  const handleCloseErrorModal = () => {
    setErrorModal(false);
    setErrorMessage(null);
  };

  const CompleteErrorModal = () => (
    <ErrorModalContainer
      onClose={handleCloseErrorModal}
      open={errorModal}
      title="Vehicle Error"
      message={errorMessage || 'An unexpected error occurred'}
      currentReader={null}
      invoiceId={null}
    />
  );

  const openSubscriptionModal = () => {
    setSubscriptionModalOpen(true);
  };

  const SubscriptionModal = () => (
    <Modal
      open={subscriptionModalOpen}
      onClose={() => setSubscriptionModalOpen(false)}
    >
      <SubscriptionContainer
        onClose={() => setSubscriptionModalOpen(false)}
        onError={(error: string | null) => {
          setSubscriptionModalOpen(false);
          setErrorMessage(error);
          setErrorModal(true);
        }}
        userVehicleId={data.userVehicleId}
      />
    </Modal>
  );

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.rowHeader}>
          <span className={styles.rowText}>{rowText}</span>
          {data.subscriptions && data.subscriptions.length > 0 && (
          <Button
            variant="secondary"
            onClick={openSubscriptionModal}
            className={styles.viewSubscriptionsButton}
          >
            View Subscription Details
          </Button>
          )}
        </div>
        <div className={styles.rowContent}>
          <div className={styles.carIdentifiers}>
            {data.vin ? (
              <p className={styles.subtitle}>
                {'VIN: '}
                {data.vin}
              </p>
            ) : (
              <>
                <TextInput
                  id="add-Vin"
                  className={styles.textInput}
                  title="Unique Vehicle Identifier"
                  placeholder="Enter Unique Vehicle Identifier"
                  type="text"
                  isRequired
                  value={data.vin}
                  onChange={handleVinChange}
                />
                <Button
                  className={styles.setButton}
                  variant="secondary"
                  size="large"
                  onClick={handleVin}
                >
                  Set VIN
                </Button>
              </>
            )}
          </div>

          <div className={styles.carIdentifiers}>
            {data.name ? (
              <p className={styles.subtitle}>
                {'Name: '}
                {data.name}
              </p>
            ) : (
              <>
                <TextInput
                  id="add-Name"
                  className={styles.textInput}
                  title="Optional Vehicle Information"
                  placeholder="Enter Name, Plate Nr. or Fleet Identifier"
                  type="text"
                  isRequired
                  value={data.name}
                  onChange={handleNameChange}
                />
                <Button
                  className={styles.setButton}
                  variant="secondary"
                  size="large"
                  onClick={handleName}
                >
                  Set Name
                </Button>
              </>
            )}
          </div>
        </div>
        <div className={styles.rowHeader}>
          {data.mileage && (
          <div className={styles.subtitle}>
            {data.lastServiced && (
            <>
              {'Last Service: '}
              {dayTimeString(data.lastServiced)}
              {' '}
            </>
            )}
            {data.mileage && (
            <>
              {'Last Mileage: '}
              {data.mileage}
              {' '}
            </>
            )}
          </div>
          )}
        </div>
        {!isManager && !isTechnician && !isTester && (
        <div className={styles.rowHeader}>
          <Button
            className={styles.setButton}
            variant="secondary"
            size="large"
            onClick={() => setMileageModal(true)}
          >
            Update Mileage
          </Button>
          <div className={styles.actionsContainer}>
            <Button
              variant="icon"
              className={styles.actions}
              onClick={handleDelete}
            >
              <Delete />
            </Button>
          </div>
        </div>
        )}
      </div>
      {mileageModal && data.userVehicleId && (
      <MileageModal
        mileageModal={mileageModal}
        onClose={() => setMileageModal(false)}
        currentMileage={data.mileage}
        userVehicleId={data.userVehicleId}
        onError={handleErrorCreatingMileage}
      />
      )}
      {errorModal && <CompleteErrorModal />}
      {subscriptionModalOpen && <SubscriptionModal />}
    </div>
  );
}
