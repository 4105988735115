import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  setCustomerId,
  setCustomerName,
} from '../features/customer/customerSlice';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import { UserVehicle } from '../interfaces/UserVehicle';
import { Vehicle } from '../interfaces/Vehicle';
import styles from '../sass/components/FranchiseCustomerManagement.module.scss';
import {
  ROUTE_BOOK_LOCATION,
  ROUTE_FRANCHISE_MANAGER_CUSTOMERS,
} from '../util/constants';
import {
  FIND_ALL_USER_VEHICLES_BY_USER_ID,
  GET_ADDRESSES_BY_USER_ID,
  GET_CONTACTS_BY_USER_ID,
  GET_USER_BY_ID,
  REMOVE_USER_DEFAULT_DISCOUNT,
  SET_USER_DEFAULT_DISCOUNT,
} from '../util/gql';
import FranchiseAddressRowEdit from './FranchiseAddressRowEdit';
import FranchiseAddressRowView from './FranchiseAddressRowView';
import Button from './Button';
import FranchiseContactRowView from './FranchiseContactRowView';
import FranchiseContactRowEdit from './FranchiseContactRowEdit';
import DiscountContainer from './DiscountContainer';
import Table from './Table';
import VehicleRowEdit from './VehicleRowEdit';
import VehicleRowView from './VehicleRowView';

export default function FranchiseCustomerManagement() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [discountType, setDiscountType] = useState<string>('');
  const [discountNum, setDiscountNum] = useState<number>(0);
  const [discountApplied, setDiscountApplied] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customer = useAppSelector((state) => state.customer);

  const currentURL = window.location.pathname;
  const customerId = currentURL.substring(
    currentURL.lastIndexOf('/') + 1,
  );

  const customerContactsData = useQuery(GET_CONTACTS_BY_USER_ID, {
    variables: {
      userId: customer.id,
    },
    fetchPolicy: 'network-only',
    skip: !customer.id,
  });

  const customerAddressData = useQuery(GET_ADDRESSES_BY_USER_ID, {
    variables: {
      userId: customer.id,
    },
    fetchPolicy: 'network-only',
    skip: !customer.id,
  });

  useQuery(GET_USER_BY_ID, {
    variables: {
      userId: customer.id,
    },
    onCompleted: (data) => {
      dispatch(setCustomerName(data.getUserById.name));
      if (data.getUserById.discountAmount && data.getUserById.discountType) {
        setDiscountNum(data.getUserById.discountAmount);
        setDiscountType(data.getUserById.discountType);
        setDiscountApplied(true);
      }
    },
    fetchPolicy: 'network-only',
    skip: !customer.id,
  });

  const [setDefaultDiscount] = useMutation(SET_USER_DEFAULT_DISCOUNT, {
    fetchPolicy: 'network-only',
  });

  const [removeDefaultDiscount] = useMutation(REMOVE_USER_DEFAULT_DISCOUNT, {
    fetchPolicy: 'network-only',
  });

  const customerVehicles = useQuery(FIND_ALL_USER_VEHICLES_BY_USER_ID, {
    variables: {
      userId: customer.id,
    },
    fetchPolicy: 'network-only',
    skip: !customer.id,
  });

  useEffect(() => {
    if (!customer.id) {
      dispatch(setCustomerId(parseInt(customerId, 10)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerVehicles.data?.findAllUserVehiclesByUserId) {
      const arr: Vehicle[] = [];
      customerVehicles.data.findAllUserVehiclesByUserId.forEach(
        (
          item:UserVehicle,
        ) => {
          const v: Vehicle = {
            id: item.vehicle.id,
            make: item.vehicle.make,
            model: item.vehicle.model,
            year: item.vehicle.year,
            userVehicleId: item.id,
            vin: item.vin,
            name: item.name,
            recommendedOil: item.vehicle.recommendedOil,
            oilVolume: item.vehicle.oilVolume,
            lastServiced: item.workOrders?.completedAt,
            subscriptions: item.subscriptions,
            ...item.mileage
            && item.mileage.length > 0
            && { mileage: item.mileage[0].mileage },
          };
          arr.push(v);
        },
      );
      setVehicles(arr);
    }
  }, [customerVehicles]);

  const calculateDiscount = () => {
    if (discountNum > 0) {
      setDiscountApplied(true);
      setDefaultDiscount({
        variables: {
          userId: customer.id,
          discountType,
          discountAmount: discountNum.toString(),
        },
      });
    }
  };

  const removeDiscount = () => {
    setDiscountApplied(false);
    setDiscountType(discountType || '');
    setDiscountNum(discountNum || 0);
    removeDefaultDiscount({
      variables: {
        userId: customer.id,
      },
    });
  };

  const backToCustomerList = (): void => navigate(
    ROUTE_FRANCHISE_MANAGER_CUSTOMERS,
  );

  const createBookingRoute = (): void => navigate(
    ROUTE_BOOK_LOCATION,
  );

  return (
    <>
      <div className={styles.backButtonContainer}>
        <div className={styles.backButtonContent}>
          <Button
            className={cx(styles.caret)}
            type="button"
            onClick={backToCustomerList}
            variant="icon"
          >
            <Arrow />
          </Button>
          <h5>
            {customer.name}
          </h5>
          <Button
            className={styles.newBookingButton}
            variant="primary"
            onClick={createBookingRoute}
          >
            + New Booking
          </Button>
        </div>
      </div>
      <div className={styles.customerInformation}>
        <div className={styles.section}>
          <h4 className={styles.header}> Default Discount </h4>
          <DiscountContainer
            discountApplied={discountApplied}
            totalOriginal={0}
            setDiscountNum={(e) => setDiscountNum(e)}
            discountType={discountType}
            discountNum={discountNum}
            setDiscountType={(e) => setDiscountType(e)}
            calculateDiscount={() => calculateDiscount()}
            removeDiscount={() => removeDiscount()}
            settingDefaultDiscount
          />
        </div>
        <div className={styles.section}>
          <h4 className={styles.header}> Contact Information </h4>
          <Table
            name="contact"
            data={customerContactsData.data?.getContactsByUserId}
            isEditable
            RowViewComponent={FranchiseContactRowView}
            RowEditComponent={FranchiseContactRowEdit}
          />
        </div>
        <div className={styles.section}>
          <h4 className={styles.header}> Customer Address(es) </h4>
          <Table
            name="address"
            data={customerAddressData.data?.getAddressesByUserId}
            isEditable
            RowViewComponent={FranchiseAddressRowView}
            RowEditComponent={FranchiseAddressRowEdit}
          />
        </div>
        <h4 className={styles.header}> Vehicle Information </h4>
        <Table
          name="vehicle"
          data={vehicles}
          isEditable
          RowViewComponent={VehicleRowView}
          RowEditComponent={VehicleRowEdit}
        />
      </div>

    </>
  );
}
