import { useState, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAppSelector } from '../app/hooks';
import { TabDictionary } from '../interfaces/TabDictionary';
import InformationSection from './InformationSection';
import InventoryPart from './InventoryPart';
import InventoryOil from './InventoryOil';
import InventoryTool from './InventoryTool';
import TabMenu from './TabMenu';
import { GET_FRANCHISE_VANS, GET_TECHNICIAN_VANS } from '../util/gql';

interface Van {
  id: number;
  name: string;
}

export default function InventoryInformation() {
  const [vans, setVans] = useState<Van[]>([]);
  const [selectedVanId, setSelectedVanId] = useState<number>(0);
  const [isVansLoading, setIsVansLoading] = useState(true);

  const franchiseId = useAppSelector((state) => state.franchise.id);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const isManager = currentUser.roles.includes('manager');
  const isTechnician = currentUser.roles.includes('technician');
  const isTester = currentUser.roles.includes('tester');

  const [getVans] = useLazyQuery(GET_FRANCHISE_VANS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const franchiseVans = data?.getFranchiseVans || [];
      setVans(franchiseVans);
      setSelectedVanId(franchiseVans.length ? franchiseVans[0].id : null);
      setIsVansLoading(false);
    },
  });

  const [getTechnicianVans] = useLazyQuery(GET_TECHNICIAN_VANS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const technicianVans = data?.getTechnicianVans || [];
      setVans(technicianVans);
      setSelectedVanId(technicianVans.length ? technicianVans[0].id : 0);
      setIsVansLoading(false);
    },
  });

  useEffect(() => {
    if ((isManager || isTester) && franchiseId) {
      setIsVansLoading(true);
      getVans({ variables: { franchiseId } });
    }

    if (isTechnician) {
      setIsVansLoading(true);
      getTechnicianVans({ variables: { technicianId: currentUser.id } });
    }
  }, [getVans,
    getTechnicianVans,
    franchiseId,
    isManager,
    isTechnician,
    isTester,
    currentUser.id]);

  const handleVanSelection = (vanId: number) => {
    setSelectedVanId(vanId);
  };

  const vanTabs = useMemo(() => vans.map((van) => ({
    id: van.id,
    label: van.name,
    isActive: selectedVanId === van.id,
    onClick: () => handleVanSelection(van.id),
  })), [vans, selectedVanId]);

  const inventoryTabs: TabDictionary = useMemo(() => ({
    inventoryPart: {
      desktopValue: 'Parts',
      mobileValue: 'Parts',
      component: (
        <InventoryPart
          emptyListMsg="No inventory"
          vanId={selectedVanId}
        />
      ),
    },
    inventoryOil: {
      desktopValue: 'Oil',
      mobileValue: 'Oil',
      component: (
        <InventoryOil
          emptyListMsg="No inventory"
          vanId={selectedVanId}
        />
      ),
    },
    inventoryTool: {
      desktopValue: 'Tools',
      mobileValue: 'Tools',
      component: (
        <InventoryTool
          emptyListMsg="No inventory"
          vanId={selectedVanId}
        />
      ),
    },
  }), [selectedVanId]);

  if (isVansLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {isTechnician && vans.length === 0 ? (
        <p>No vans available. Please ask a manager to assign a van.</p>
      ) : (
        <>
          {vans.length > 1 && <TabMenu tabs={vanTabs} />}
          {Object.keys(inventoryTabs).length > 0 && (
            <InformationSection
              tabs={inventoryTabs}
              initialState="inventoryPart"
            />
          )}
        </>
      )}
    </div>
  );
}
