import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import useAuthentication from '../hooks/useAuthentication';
import { PartItems, PartItem } from '../interfaces/Inventory';
import styles from '../sass/components/InventoryPart.module.scss';
import {
  GET_INVENTORY_PART_FOR_FRANCHISE,
  INCREASE_PART_QUANTITY,
} from '../util/gql';
import Modal from './Modal';
import Button from './Button';
import EmptyList from './EmptyList';
import TextInput from './TextInput';
import { ReactComponent as Search } from '../images/search.svg';
import AddPartContainer from './AddPartContainer';
import IncreaseInventoryContainer from './IncreaseInventoryContainer';
import ErrorModalContainer from './CompleteModal';
import DeleteInventoryPartModal from './DeleteInventoryPartModal';

interface InventoryPartProps {
  vanId: number;
  emptyListMsg: string;
}

export default function InventoryPart(
  { emptyListMsg, vanId }: InventoryPartProps,
) {
  const [inventoryItems, setInventoryItems] = useState<PartItems>([]);
  const [addPartModalOpen, setAddPartModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [
    increaseInventoryModalOpen,
    setIncreaseInventoryModalOpen,
  ] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [
    selectedInventoryPartItemId,
    setSelectedInventoryPartItemId,
  ] = useState<string>('');
  const [selectedPartName, setSelectedPartName] = useState<string>('');

  const [filter, setFilter] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [currentCount, setCurrentCount] = useState<number>(0);

  const { loggedIn: isLoggedIn } = useAuthentication();
  const { franchiseId } = useAppSelector((state) => state.auth.currentUser);
  const franchiseIdSelect = useAppSelector((state) => state.franchise.id);
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const isManager = currentUser.roles?.includes('manager');
  const isTester = currentUser.roles?.includes('tester');
  const isTechnician = currentUser.roles?.includes('technician');

  const [
    getInventoryPartItems,
    { loading },
  ] = useLazyQuery(GET_INVENTORY_PART_FOR_FRANCHISE, {
    onCompleted: (data) => {
      // eslint-disable-next-line max-len
      const inventoryPartList = data?.getInventoryPartForFranchise.inventoryPartItems;
      setCurrentCount(currentCount + inventoryPartList.length);
      setTotal(data?.getInventoryPartForFranchise.total);
      setInventoryItems((prevItems) => [...prevItems, ...inventoryPartList]);
    },
    onError: (err) => {
      setErrorMessage(`Failed to load inventory parts: ${err.message}`);
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const fetchInventoryParts = () => {
    const setFranchiseId = franchiseIdSelect || franchiseId;
    setInventoryItems([]);
    setCurrentPage(1);
    setCurrentCount(0);
    setTotal(0);
    getInventoryPartItems({
      variables: {
        franchiseId: setFranchiseId,
        vanId,
        pageNumber: 1,
        filter,
      },
    });
  };

  const [increasePartQuantity] = useMutation(INCREASE_PART_QUANTITY, {
    onCompleted: () => fetchInventoryParts(),
    onError: (error) => {
      if (error instanceof Error) {
        setErrorMessage(`Error increasing quantity: ${error.message}`);
      } else {
        setErrorMessage('An unknown error occurred while increasing quantity.');
      }
      setErrorModal(true);
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      fetchInventoryParts();
    }
  }, [isLoggedIn,
    franchiseId,
    franchiseIdSelect,
    vanId]);

  const openDeleteModal = (itemId: string, itemName: string) => {
    setSelectedInventoryPartItemId(itemId);
    setSelectedPartName(itemName);
    setDeleteModalOpen(true);
  };

  const handleDeleteSuccess = () => {
    fetchInventoryParts();
  };

  const openIncreaseInventoryModal = (
    inventoryPartItemId: string,
    partName: string,
  ) => {
    setSelectedInventoryPartItemId(inventoryPartItemId);
    setSelectedPartName(partName);
    setIncreaseInventoryModalOpen(true);
  };

  const handleIncreaseInventory = async (
    data: {
      quantity: number;
      pricePerItem: number | null;
      receiptImage: string },
  ) => {
    if (selectedInventoryPartItemId) {
      const inventoryPartItemId = parseInt(selectedInventoryPartItemId, 10);
      try {
        await increasePartQuantity({
          variables: {
            inventoryPartItemId,
            increment: data.quantity,
            pricePerItem: data.pricePerItem,
            receiptImage: data.receiptImage,
          },
        });
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(`Error increasing quantity: ${error.message}`);
        } else {
          setErrorMessage(
            'An unknown error occurred while increasing quantity.',
          );
        }
        setErrorModal(true);
      } finally {
        setIncreaseInventoryModalOpen(false);
        setSelectedInventoryPartItemId('');
        setSelectedPartName('');
      }
    }
  };

  const handleSearch = () => {
    fetchInventoryParts();
  };

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);

    getInventoryPartItems({
      variables: {
        franchiseId: franchiseIdSelect || franchiseId,
        vanId,
        pageNumber: nextPage,
        filter,
      },
    });
  };

  const handleCloseErrorModal = () => {
    setErrorModal(false);
    setErrorMessage(null);
  };

  const AddPartModal = () => (
    <Modal
      open={addPartModalOpen}
      onClose={() => setAddPartModalOpen(false)}
      disableBackdropClick
    >
      <AddPartContainer
        onClose={() => setAddPartModalOpen(false)}
        onError={(error) => {
          setErrorMessage(error);
          setErrorModal(true);
        }}
        franchiseId={franchiseIdSelect || franchiseId}
        vanId={vanId}
        refetchItems={fetchInventoryParts}
      />
    </Modal>
  );

  const IncreaseInventoryModal = () => (
    <Modal
      open={increaseInventoryModalOpen}
      onClose={() => setIncreaseInventoryModalOpen(false)}
    >
      <IncreaseInventoryContainer
        partId={selectedInventoryPartItemId}
        partName={selectedPartName}
        onClose={() => setIncreaseInventoryModalOpen(false)}
        onSubmit={handleIncreaseInventory}
      />
    </Modal>
  );

  const CompleteErrorModal = () => (
    <ErrorModalContainer
      onClose={handleCloseErrorModal}
      open={errorModal}
      title="Inventory Error"
      message={errorMessage || 'An unexpected error occurred'}
      currentReader={null}
      invoiceId={null}
    />
  );

  const content = inventoryItems.length > 0 ? (
    <>
      <table className={styles.inventoryTable}>
        <thead>
          <tr>
            <th>Part Name</th>
            <th>Part Type</th>
            <th>Quantity</th>
            <th>{isManager || isTechnician || isTester ? 'Actions' : ''}</th>
          </tr>
        </thead>
        <tbody>
          {inventoryItems.map((item: PartItem) => (
            <tr key={item.id}>
              <td>{item.partItem.name}</td>
              <td>{item.partItem.partType.name}</td>
              <td>{item.quantity}</td>
              <td>
                {(isManager || isTechnician || isTester) && (
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                      variant="secondary"
                      onClick={
                        () => openIncreaseInventoryModal(
                          String(item.id),
                          item.partItem.name,
                        )
  }
                    >
                      Restock
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={
                        () => openDeleteModal(
                          String(item.id),
                          item.partItem.name,
                        )
  }
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {currentCount < total && (
        <div className={styles.loadMoreButtonContainer}>
          <Button
            variant="secondary"
            onClick={handleLoadMore}
            className={styles.loadMoreButton}
          >
            Load more
          </Button>
        </div>
      )}
    </>
  ) : (
    <EmptyList msg={emptyListMsg} />
  );

  return (
    <div className={styles.itemsList}>
      <div className={styles.searchDiv}>
        <div className={styles.searchBar}>
          <div className={styles.magnifyingGlass}>
            <Search />
          </div>
          <TextInput
            placeholder="Search Parts by Name or Type"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.currentTarget.value);
              setFilter(e.currentTarget.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </div>
        <Button
          variant="primary"
          onClick={handleSearch}
          className={styles.searchButton}
        >
          Search
        </Button>
        {(isManager || isTechnician || isTester) && (
          <Button
            variant="primary"
            className={styles.searchButton}
            onClick={() => setAddPartModalOpen(true)}
          >
            Add Part
          </Button>
        )}
        {addPartModalOpen && <AddPartModal />}
        {errorModal && <CompleteErrorModal />}
        {increaseInventoryModalOpen && <IncreaseInventoryModal />}
        {deleteModalOpen && (
        <DeleteInventoryPartModal
          itemId={selectedInventoryPartItemId}
          itemName={selectedPartName}
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onDeleteSuccess={handleDeleteSuccess}
          setErrorModal={setErrorModal}
        />
        )}
      </div>
      {loading ? <p className={styles.loading}>Loading...</p> : content}
    </div>
  );
}
