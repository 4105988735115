import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FranchiseState {
  id?: number;
  name?: string;
  countryCode?: string;
  inventory?: boolean;
}

const initialState: FranchiseState = {
  name: '',
  countryCode: '',
  inventory: true,
};

export const franchiseSlice = createSlice({
  name: 'franchise',
  initialState,
  reducers: {
    setFrnchiseId: (state, action: PayloadAction<number>) => ({
      ...state,
      id: action.payload,
    }),
    setFranchiseName: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      name: action.payload,
    }),
    setFranchiseCountryCode: (state,
      action: PayloadAction<string | undefined>) => ({
      ...state,
      countryCode: action.payload,
    }),
    setInventoryFeature: (state,
      action: PayloadAction<boolean | true>) => ({
      ...state,
      inventory: action.payload,
    }),
  },
});

export const {
  setFrnchiseId,
  setFranchiseName,
  setFranchiseCountryCode,
  setInventoryFeature,
} = franchiseSlice.actions;

export default franchiseSlice.reducer;
