import cx from 'classnames';
import { useEffect, useState } from 'react';
import { InvoiceDiscountProps } from '../interfaces/Components';
import styles from '../sass/components/InvoiceDiscount.module.scss';
import DiscountContainer from './DiscountContainer';
import ModalButtonsContainer from './ModalButtonsContainer';
import { LineItem } from '../interfaces/WorkOrder';

export default function InvoiceDiscount(
  {
    totalOriginal,
    workOrderDetails,
    setInvoiceDiscountDetails,
    onBack,
    onClick,
    selectedSubscription,
    discountedLineItems,
  }: InvoiceDiscountProps,
) {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1200);
  const [discountApplied, setDiscountApplied] = useState<boolean>(false);
  const [totalDiscounted, setTotalDiscounted] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string>(
    workOrderDetails?.userDiscountType
      ? workOrderDetails?.userDiscountType : '',
  );
  const [discountNum, setDiscountNum] = useState<number>(() => {
    if (workOrderDetails?.workOrderDiscountCode?.amountApplied) {
      return workOrderDetails.workOrderDiscountCode.amountApplied;
    }
    return workOrderDetails?.userDiscountAmount
      ? parseInt(workOrderDetails.userDiscountAmount, 10)
      : 0;
  });
  const [discountCodeId, setDiscountCodeId] = useState<string | undefined>(
    workOrderDetails?.workOrderDiscountCode?.discountCodeId,
  );
  const [
    discountCodeApplied,
    setDiscountCodeApplied,
  ] = useState<number | undefined>(
    workOrderDetails?.workOrderDiscountCode?.amountApplied,
  );

  const selectedServices = workOrderDetails?.lineitems?.map((item) => ({
    value: item.discountedPrice ?? item.price,
    name: item.name,
    id: item.service.id,
  }));

  useEffect(() => {
    const resize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  const calculateDiscount = () => {
    let totalAfterDiscount = 0;
    if (discountCodeApplied) {
      setDiscountApplied(true);

      setDiscountType('dollar');
      const newDiscountType = 'dollar';
      const discountedItems = totalOriginal - (discountNum / 100);
      totalAfterDiscount = parseFloat((discountedItems).toFixed(2));

      if (selectedSubscription) {
        const newTotal = discountedItems + (selectedSubscription.price / 100);
        totalAfterDiscount = parseFloat((newTotal).toFixed(2));
      }
      setTotalDiscounted(totalAfterDiscount);
      setInvoiceDiscountDetails(
        newDiscountType,
        discountNum,
        discountCodeId,
      );
      return;
    }

    if (discountType === 'percent') {
      setDiscountApplied(true);
      const discountedItems = totalOriginal
        * ((100 - (discountNum / 100)) / 100);
      if (selectedSubscription) {
        const newTotal = discountedItems + (selectedSubscription.price / 100);
        totalAfterDiscount = parseFloat((newTotal).toFixed(2));
      } else {
        totalAfterDiscount = parseFloat(discountedItems.toFixed(2));
      }
      setTotalDiscounted(totalAfterDiscount);
      if (discountNum > 0 && totalAfterDiscount >= 0) {
        setDiscountApplied(true);
        setInvoiceDiscountDetails(discountType, discountNum);
      }
      return;
    }

    if (!discountCodeApplied && discountType === 'dollar') {
      setDiscountType('dollar');
      const discountedItems = totalOriginal - (discountNum / 100);
      totalAfterDiscount = parseFloat((discountedItems).toFixed(2));

      if (selectedSubscription) {
        const newTotal = discountedItems + (selectedSubscription.price / 100);
        totalAfterDiscount = parseFloat((newTotal).toFixed(2));
      }
      setTotalDiscounted(totalAfterDiscount);

      if (discountNum > 0 && totalAfterDiscount >= 0) {
        setDiscountApplied(true);
        setInvoiceDiscountDetails(discountType, discountNum);
      }
    }
  };

  const handleDiscountApplied = (newDiscountAmount: number, id?: string) => {
    setDiscountCodeApplied(newDiscountAmount);
    setDiscountCodeId(id);
    calculateDiscount();
  };

  useEffect(() => {
    if ((workOrderDetails?.userDiscountAmount
        && workOrderDetails?.userDiscountType) || discountCodeApplied) {
      calculateDiscount();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeDiscount = () => {
    setDiscountApplied(false);
    setDiscountCodeApplied(undefined);
    setDiscountCodeId(undefined);
    setDiscountType(workOrderDetails?.userDiscountType
      ? workOrderDetails?.userDiscountType : '');
    setDiscountNum(workOrderDetails?.userDiscountAmount
      ? parseInt(workOrderDetails?.userDiscountAmount, 10) : 0);
    setInvoiceDiscountDetails('', 0);
  };

  const updatedTotal = () => {
    if (!selectedSubscription) return totalOriginal;
    return (totalOriginal + selectedSubscription.price / 100).toFixed(2);
  };

  const getServicePrice = ({ price, discountedPrice, service }: LineItem) => {
    const serviceId = service?.id;

    const matchingDiscountedItem = discountedLineItems?.find(
      (dItem) => dItem.serviceId === serviceId,
    );

    const finalDiscountedPrice = matchingDiscountedItem
      ? matchingDiscountedItem.discountedPrice
      : discountedPrice;

    return finalDiscountedPrice !== null
      && finalDiscountedPrice !== undefined
      && Number.isFinite(finalDiscountedPrice)
      ? finalDiscountedPrice / 100
      : price / 100;
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <h4>Step 6/6: Complete Order and Invoice</h4>
        <p>
          Completing this order will generate an invoice and send it
          to the customer. Do you wish to continue?
        </p>
      </div>
      <div className={styles.cardContainer}>
        <p className={styles.title}>
          Services performed
          {!isMobile && workOrderDetails?.vin ? `${' for '}
        ${workOrderDetails?.vin}` : ''}
        </p>
        <div className={cx('label', styles.cardContent)}>
          {
            workOrderDetails?.lineitems.map((item) => (
              <div key={item.name} className={styles.lineItem}>
                <p>{item.name}</p>
                <p className={styles.price}>
                  $
                  {getServicePrice(item)}
                </p>
              </div>
            ))
          }
          {selectedSubscription && (
            <div className={styles.lineItem}>
              <p>{selectedSubscription.aliasName}</p>
              <p className={styles.price}>
                $
                {selectedSubscription.price / 100}
              </p>
            </div>
          )}
        </div>
        <div className={cx('label', styles.cardContent)}>
          <div className={styles.lineItem_total}>
            <p>
              Total (before tax)
            </p>
            <p className={styles.price_total}>
              $
              {discountApplied ? totalDiscounted : updatedTotal()}
            </p>
          </div>
        </div>
        <DiscountContainer
          discountApplied={discountApplied}
          discountCodeApplied={discountCodeApplied}
          discountCodeIdApplied={discountCodeId}
          totalOriginal={totalOriginal}
          setDiscountNum={(e) => !discountCodeApplied && setDiscountNum(e)}
          discountType={!discountCodeApplied ? discountType : 'dollar'}
          discountNum={discountNum}
          setDiscountType={(e) => !discountCodeApplied && setDiscountType(e)}
          calculateDiscount={calculateDiscount}
          removeDiscount={removeDiscount}
          settingDefaultDiscount={false}
          userId={workOrderDetails?.userId}
          carId={workOrderDetails?.vehicleId}
          selectedServices={selectedServices}
          onDiscountUpdate={handleDiscountApplied}
        />
      </div>
      <ModalButtonsContainer
        onClose={onBack}
        onClick={onClick}
        label="Complete"
      />
    </div>
  );
}
