import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import { DecreaseInventoryToolModalProps } from '../interfaces/Inventory';
import TextInput from './TextInput';

export default function IncreaseInventoryModal({
  toolName,
  reason,
  setReason,
  onClose,
  onSubmit,
}: DecreaseInventoryToolModalProps) {
  const handleReasonChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    setReason(target.value);
  };

  const handleConfirm = () => {
    onSubmit({ reason });
  };

  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Decrease Inventory Quantity</h4>

        <p className={styles.partName}>
          {toolName}
        </p>

        <p className="label">
          Reason for decreasing this tool.
        </p>

        <TextInput
          placeholder="Enter removal reason"
          value={reason}
          onChange={handleReasonChange}
          isRequired
        />
        <p>An email notification will be sent to the franchise manager.</p>

        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} variant="tertiary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="primary"
            inactive={!reason.trim()}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
