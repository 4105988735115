import { useState } from 'react';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import IncreaseInventoryToolModal from './IncreaseInventoryToolModal';

interface IncreaseInventoryToolContainerProps {
  vanToolId: string;
  toolName: string;
  onClose: () => void;
  onSubmit: (data: {
    quantity: number;
  }) => void;
}

export default function IncreaseInventoryToolContainer({
  vanToolId,
  toolName,
  onClose,
  onSubmit,
}: IncreaseInventoryToolContainerProps) {
  const [quantity, setQuantity] = useState<number>(1);

  const handleSubmit = () => {
    onSubmit({
      quantity,
    });
    onClose();
  };

  return (
    <div className={styles.container}>
      <IncreaseInventoryToolModal
        toolName={toolName}
        vanToolId={vanToolId}
        quantity={quantity}
        setQuantity={setQuantity}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
