import { useLazyQuery } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import SubscriptionModal from './SubscriptionModal';
import {
  GET_SUBSCRIPTIONS,
} from '../util/gql';

interface SubscriptionContainerProps {
  onClose: () => void;
  onError: (error: string) => void;
  userVehicleId?: number;
}

export default function SubscriptionContainer({
  onClose,
  onError,
  userVehicleId,
}: SubscriptionContainerProps) {
  const [subscriptions, setSubscriptions] = useState([]);

  const [
    getSubscriptions,
  ] = useLazyQuery(GET_SUBSCRIPTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSubscriptions(data.getSubscriptions);
    },
    onError: (err) => {
      onError(`Failed to load subscriptions: ${err.message}`);
    },
  });

  useEffect(() => {
    if (userVehicleId) {
      getSubscriptions({ variables: { userVehicleId } });
    }
  }, [userVehicleId, getSubscriptions]);

  const refetchSubscriptions = useCallback(() => {
    if (userVehicleId) {
      getSubscriptions({ variables: { userVehicleId } });
    }
  }, [userVehicleId, getSubscriptions]);

  return (
    <SubscriptionModal
      onClose={onClose}
      subscriptions={subscriptions}
      refetchSubscriptions={refetchSubscriptions}
    />
  );
}
