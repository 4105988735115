import { Subscription } from '../interfaces/Subscription';
import {
  SubscriptionStatus,
  VehicleSubscription,
} from '../interfaces/WorkOrder';

const nonBillableSubscriptions = [
  SubscriptionStatus.active,
  SubscriptionStatus.pending,
];

export const getCurrentVehicleSubscription = (
  vehicleSubscriptions: VehicleSubscription[] = [],
): VehicleSubscription | undefined => {
  const validSubscriptions = vehicleSubscriptions.filter(
    (vehicleSubscription) => nonBillableSubscriptions
      .includes(vehicleSubscription.status),
  );

  return (validSubscriptions.length >= 0) ? validSubscriptions[0] : undefined;
};

export const getActiveVehicleSubscription = (
  vehicleSubscriptions: VehicleSubscription[] = [],
): VehicleSubscription | undefined => {
  const validSubscriptions = vehicleSubscriptions.filter(
    (vehicleSubscription) => vehicleSubscription.status
    === SubscriptionStatus.active,
  );

  return (validSubscriptions.length >= 0) ? validSubscriptions[0] : undefined;
};

const addDuration = (
  date: Date,
  duration: { months?: number; years?: number },
): Date => {
  const newDate = new Date(date);

  if (duration.months) {
    newDate.setMonth(newDate.getMonth() + duration.months);
  }

  if (duration.years) {
    newDate.setFullYear(newDate.getFullYear() + duration.years);
  }

  return newDate;
};

export const calculateRenewingDate = ({
  subscriptionType: { frequency },
  createdAt,
  renewedAt,
}: Subscription): Date => {
  const frequencyString = frequency.toString();

  const period = Number(
    process.env[`SUBSCRIPTION_${frequencyString}_RENEW_PERIOD`]
    || 1,
  );

  const duration: { months?: number; years?: number } = {
    months: 0,
    years: 0,
  };

  if (frequency === 'MONTHLY') {
    duration.months = period;
  } else if (frequency === 'ANNUALLY') {
    duration.years = period;
  }

  const currentDate = new Date(renewedAt || createdAt);

  return addDuration(currentDate, duration);
};
