/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  EditLineItemsModalProps,
  EditLineItemsModals,
  editLineItemsModalsList,
  Services,
} from '../interfaces/Components';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import { GET_ALL_SERVICES } from '../util/gql';
import ModalCloseButton from './ModalCloseButton';
import LineItemsEditor from './LineItemsEditor';
import {
  ServiceItem,
} from '../interfaces/WorkOrder';

export default function EditLineItemsModal(
  {
    workOrderDetails, setWorkOrderDetails, onClose, onSubmit,
  }: EditLineItemsModalProps,
) {
  const [services, setServices] = useState<Services[]>([]);
  const [serviceItem, setServiceItem] = useState<ServiceItem[]>([]);
  const [poNumber, setPoNumber] = useState<string>('');
  const { data, loading } = useQuery(GET_ALL_SERVICES, {
    variables: { workOrderId: workOrderDetails?.id },
    onCompleted: () => {
      if (data.getAllServices) {
        setServices(data.getAllServices);
      }
    },
    fetchPolicy: 'network-only',
  });

  if (workOrderDetails) {
    useEffect(() => {
      if (workOrderDetails?.lineitems?.length > 0) {
        // eslint-disable-next-line max-len
        const updatedLineItems = workOrderDetails.lineitems.map((item, index) => {
          const newItem = {
            id: item.id,
            price: item.price ? item.price / 100 : 0,
            tax: item.tax,
            description: '',
            quantity: 1,
            name: item.name,
            discountedPrice: item.discountedPrice !== undefined
            && item.discountedPrice !== null
              ? item.discountedPrice / 100
              : undefined,
            serviceId: item.service.id,
          };
          if (workOrderDetails.services && workOrderDetails.services[index]) {
            // eslint-disable-next-line max-len
            newItem.description = workOrderDetails.services[index] || newItem.description;
          }

          return newItem;
        });

        setServiceItem(updatedLineItems);
        if (workOrderDetails?.poNumber) {
          setPoNumber(workOrderDetails?.poNumber);
        }

        // setPoNumber();
      } else {
        setServiceItem([]);
      }
    }, [workOrderDetails]);
  }
  const [
    activeModal,
    setActiveModal,
  ] = useState<EditLineItemsModals>('lineItems');
  const handleSubmit = () => {
    onSubmit(
      serviceItem,
      poNumber,
    );
    onClose();
  };

  const handleChangeActiveModal = (
    modalName: EditLineItemsModals,
  ) => setActiveModal(modalName);

  const handleModalContinue = () => {
    const currentModalIndex = editLineItemsModalsList.indexOf(activeModal);
    const nextModal = editLineItemsModalsList[currentModalIndex + 1];
    handleChangeActiveModal(nextModal);
  };

  const handleModalBack = () => {
    const currentModalIndex = editLineItemsModalsList.indexOf(activeModal);
    const nextModal = editLineItemsModalsList[currentModalIndex - 1];
    handleChangeActiveModal(nextModal);
  };

  return (
    <div className={styles.container}>
      <ModalCloseButton onClose={onClose} />
      {activeModal === 'lineItems' && workOrderDetails && (
        <LineItemsEditor
          workOrderDetails={workOrderDetails}
          setWorkOrderDetails={setWorkOrderDetails}
          serviceItem={serviceItem}
          setServiceItem={setServiceItem}
          services={services}
          poNumber={poNumber}
          setPoNumber={setPoNumber}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}
