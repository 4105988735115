import { useEffect, useState } from 'react';
import styles from '../sass/components/DiscountContainer.module.scss';
import Button from './Button';
import TextInput from './TextInput';
import DiscountCodeInput from './DiscountCodeInput';
import { discountedFeesNames } from '../constants/discountedFees';

interface InvoiceDiscountProps {
  discountApplied: boolean
  discountCodeApplied?: number;
  discountCodeIdApplied?: string;
  totalOriginal: number
  setDiscountNum: (discountNum: number) => void
  discountType: string,
  discountNum: number,
  setDiscountType: (discountType: string) => void
  calculateDiscount: () => void
  removeDiscount: (() => void)
  settingDefaultDiscount: boolean
  carId?: number
  userId?: number
  selectedServices?: {
    value: number;
    name: string;
    id: number;
  }[];
  onDiscountUpdate?: (discountAmount: number, discointCodeId?: string) => void;
}

export default function DiscountContainer({
  discountApplied,
  discountCodeIdApplied,
  discountCodeApplied,
  totalOriginal,
  setDiscountNum,
  discountType,
  discountNum,
  setDiscountType,
  calculateDiscount,
  removeDiscount,
  settingDefaultDiscount,
  carId,
  userId,
  selectedServices,
  onDiscountUpdate,
}:InvoiceDiscountProps) {
  const [readyToDiscount, setReadyToDiscount] = useState<boolean>(false);
  const [autofillDiscount, setAutofillDiscount] = useState<string>('');
  const [discountCode, setDiscountCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [, setDiscountAmount] = useState(0);
  const [discountCodeId, setDiscountCodeId] = useState<string | undefined>(
    discountCodeIdApplied,
  );

  const discountedFees = selectedServices?.filter(
    (service) => discountedFeesNames.includes(service.name),
  ) || [];

  const newDiscountFeesAmount = discountedFees.reduce(
    (total, fee) => total + fee.value,
    0,
  );

  const handleDiscountApplied = (newDiscountAmount: number,
    id?: string) => {
    setDiscountAmount(newDiscountAmount + newDiscountFeesAmount);

    if (id) setDiscountCodeId(id);

    if (onDiscountUpdate) {
      onDiscountUpdate(newDiscountAmount, id);
      setDiscountNum(newDiscountAmount + newDiscountFeesAmount);
    }
  };

  useEffect(() => {
    if (discountCodeApplied) {
      handleDiscountApplied(discountCodeApplied, discountCodeId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCodeApplied, discountCodeId]);
  useEffect(() => {
    const isDiscountValid = () => {
      if (settingDefaultDiscount) {
        const isValidDiscountNum = discountNum > 0;
        const isValidDiscountType = discountType !== '';
        const isValidPercentDiscount = discountType === 'percent'
          ? discountNum / 100 <= 100 : true;

        return isValidDiscountNum
          && isValidDiscountType && isValidPercentDiscount;
      }
      const isValidDiscountNum = discountNum > 0;
      const isValidDiscountType = discountType !== '';
      const isValidDollarDiscount = discountType === 'dollar'
          && discountNum / 100 <= totalOriginal;
      const isValidPercentDiscount = discountType === 'percent'
          && discountNum / 100 <= 100;

      return isValidDiscountNum
          && isValidDiscountType
          && (isValidDollarDiscount || isValidPercentDiscount);
    };

    setReadyToDiscount(isDiscountValid());
  }, [discountNum, discountType, settingDefaultDiscount, totalOriginal]);

  useEffect(() => {
    if (discountNum) {
      const num = discountNum / 100;
      setAutofillDiscount(num.toString());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!discountApplied && (
      <div className={settingDefaultDiscount
        ? styles.default_discount_input : styles.invoice_discount_input}
      >
        {!settingDefaultDiscount && (
        <p className={styles.discount_title}>
          Discount
        </p>
        )}
        <div className={styles.discount_setting}>
          <TextInput
            className={styles.discount_input_box}
            onChange={(e) => setDiscountNum(
              Math.round(parseFloat(e.currentTarget.value) * 100),
            )}
            placeholder={autofillDiscount}
            min={0}
          />
          <Button
            className={`${styles.discount_type}
            ${discountType === 'dollar' && styles.active}`}
            onClick={() => setDiscountType('dollar')}
            variant="secondary"
          >
            $
          </Button>
          <Button
            className={`${styles.discount_type}
            ${discountType === 'percent' && styles.active}`}
            onClick={() => setDiscountType('percent')}
            variant="secondary"
          >
            %
          </Button>
        </div>
        <Button
          className={styles.apply_remove_discount}
          onClick={calculateDiscount}
          variant="secondary"
          inactive={!readyToDiscount}
        >
          Apply Discount
        </Button>
      </div>
      )}
      { !discountCodeApplied && userId && carId
      && selectedServices && selectedServices.length > 0
      && (
        <div className={styles.invoice_discount_input}>
          <DiscountCodeInput
            discountCode={discountCode}
            setDiscountCode={setDiscountCode}
            selectedServices={selectedServices}
            carId={carId}
            userId={userId}
            onDiscountApplied={handleDiscountApplied}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </div>
      )}
      { (discountCodeApplied || discountApplied) && (
      <div className={settingDefaultDiscount
        ? styles.default_discount_input : styles.invoice_discount_input}
      >
        {!settingDefaultDiscount && (
        <p className={styles.discount_title}>
          Discount
        </p>
        )}
        <p className={styles.discount_applied}>
          -
          {' '}
          {discountType === 'dollar' ? '$' : ''}
          {discountNum / 100}
          {discountType === 'percent' ? '%' : ''}
          {' '}
          applied
        </p>
        <Button
          className={styles.apply_remove_discount}
          onClick={() => removeDiscount()}
          variant="secondary"
        >
          Remove Discount
        </Button>
      </div>
      )}
    </>
  );
}
