import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import styles from '../sass/components/InventoryPart.module.scss';
import {
  GET_INVENTORY_OIL_FOR_FRANCHISE,
  INCREASE_OIL_QUANTITY,
} from '../util/gql';
import { useAppSelector } from '../app/hooks';
import useAuthentication from '../hooks/useAuthentication';
import { OilItems, OilItem } from '../interfaces/Inventory';
import Modal from './Modal';
import Button from './Button';
import EmptyList from './EmptyList';
import TextInput from './TextInput';
import { ReactComponent as Search } from '../images/search.svg';
import AddOilContainer from './AddOilContainer';
import IncreaseInventoryOilContainer from './IncreaseInventoryOilContainer';
import ErrorModalContainer from './CompleteModal';
import DeleteInventoryOilModal from './DeleteInventoryOilModal';
import {
  calculateOilByCountry,
  convertToLiters,
} from '../util/oilHelper';

interface InventoryOilProps {
  vanId: number;
  emptyListMsg: string;
}

export default function InventoryOil(
  { emptyListMsg, vanId }: InventoryOilProps,
) {
  const [inventoryItems, setInventoryItems] = useState<OilItems>([]);
  const [addOilModalOpen, setAddOilModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [
    increaseInventoryOilModalOpen,
    setIncreaseInventoryOilModalOpen,
  ] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedInventoryOilItemId,
    setSelectedInventoryOilItemId,
  ] = useState<string>('');
  const [selectedOilName, setSelectedOilName] = useState<string>('');
  const [selectedOilWeight, setSelectedOilWeight] = useState<string>('');

  const [filter, setFilter] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [currentCount, setCurrentCount] = useState<number>(0);

  const { loggedIn: isLoggedIn } = useAuthentication();
  const { id: franchiseIdSelect, countryCode } = useAppSelector(
    (state) => state.franchise,
  );
  const { franchiseId } = useAppSelector((state) => state.auth.currentUser);
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const isManager = currentUser.roles?.includes('manager');
  const isTester = currentUser.roles?.includes('tester');
  const isTechnician = currentUser.roles?.includes('technician');

  const [
    getInventoryOilItems,
    { loading }] = useLazyQuery(GET_INVENTORY_OIL_FOR_FRANCHISE, {
    onCompleted: (data) => {
      // eslint-disable-next-line max-len
      const inventoryOilList = data?.getInventoryOilForFranchise.inventoryOilItems;
      setCurrentCount(currentCount + inventoryOilList.length);
      setTotal(data?.getInventoryOilForFranchise.total);
      setInventoryItems((prevItems) => [...prevItems, ...inventoryOilList]);
    },
    onError: (err) => {
      setErrorMessage(`Failed to load inventory oils: ${err.message}`);
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const fetchInventoryOils = () => {
    const setFranchiseId = franchiseIdSelect || franchiseId;
    setInventoryItems([]);
    setCurrentPage(1);
    setCurrentCount(0);
    setTotal(0);
    getInventoryOilItems({
      variables: {
        franchiseId: setFranchiseId,
        pageNumber: 1,
        filter,
        vanId,
      },
    });
  };

  const [increaseOilQuantity] = useMutation(INCREASE_OIL_QUANTITY, {
    onCompleted: () => fetchInventoryOils(),
    onError: (error) => {
      if (error instanceof Error) {
        setErrorMessage(`Error increasing quantity: ${error.message}`);
      } else {
        setErrorMessage('An unknown error occurred while increasing quantity.');
      }
      setErrorModal(true);
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      fetchInventoryOils();
    }
  }, [isLoggedIn,
    franchiseId,
    franchiseIdSelect,
    vanId]);

  const openDeleteModal = (itemId: string,
    itemName: string,
    oilWeight: string) => {
    setSelectedInventoryOilItemId(itemId);
    setSelectedOilName(itemName);
    setSelectedOilWeight(oilWeight);
    setDeleteModalOpen(true);
  };

  const handleDeleteSuccess = () => {
    fetchInventoryOils();
  };

  const openIncreaseInventoryOilModal = (
    inventoryOilItemId: string,
    oilName: string,
    oilWeight: string,
  ) => {
    setSelectedInventoryOilItemId(inventoryOilItemId);
    setSelectedOilName(oilName);
    setSelectedOilWeight(oilWeight);
    setIncreaseInventoryOilModalOpen(true);
  };

  const handleIncreaseInventory = async (
    data: {
      quantity: number;
      pricePerItem: number | null;
      receiptImage: string },
  ) => {
    if (selectedInventoryOilItemId) {
      const inventoryOilItemId = parseInt(selectedInventoryOilItemId, 10);
      const quantityInLiters = convertToLiters(
        String(countryCode),
        data.quantity,
      );
      try {
        await increaseOilQuantity({
          variables: {
            inventoryOilItemId,
            increment: quantityInLiters,
            pricePerItem: data.pricePerItem,
            receiptImage: data.receiptImage,
          },
        });
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(`Error increasing quantity: ${error.message}`);
        } else {
          setErrorMessage(
            'An unknown error occurred while increasing quantity.',
          );
        }
        setErrorModal(true);
      } finally {
        setIncreaseInventoryOilModalOpen(false);
        setSelectedInventoryOilItemId('');
        setSelectedOilName('');
        setSelectedOilWeight('');
      }
    }
  };

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);

    getInventoryOilItems({
      variables: {
        franchiseId: franchiseIdSelect || franchiseId,
        vanId,
        pageNumber: nextPage,
        filter,
      },
    });
  };

  const handleSearch = () => {
    fetchInventoryOils();
  };

  const handleCloseErrorModal = () => {
    setErrorModal(false);
    setErrorMessage(null);
  };

  const AddOilModal = () => (
    <Modal
      open={addOilModalOpen}
      onClose={() => setAddOilModalOpen(false)}
      disableBackdropClick
    >
      <AddOilContainer
        onClose={() => setAddOilModalOpen(false)}
        onError={(error) => {
          setErrorMessage(error);
          setErrorModal(true);
        }}
        franchiseId={franchiseIdSelect || franchiseId}
        vanId={vanId}
        refetchItems={fetchInventoryOils}
      />
    </Modal>
  );

  const IncreaseInventoryOilModal = () => (
    <Modal
      open={increaseInventoryOilModalOpen}
      onClose={() => setIncreaseInventoryOilModalOpen(false)}
    >
      <IncreaseInventoryOilContainer
        oilId={selectedInventoryOilItemId}
        oilName={selectedOilName}
        oilWeight={selectedOilWeight}
        onClose={() => setIncreaseInventoryOilModalOpen(false)}
        onSubmit={handleIncreaseInventory}
      />
    </Modal>
  );

  const CompleteErrorModal = () => (
    <ErrorModalContainer
      onClose={handleCloseErrorModal}
      open={errorModal}
      title="Inventory Error"
      message={errorMessage || 'An unexpected error occurred'}
      currentReader={null}
      invoiceId={null}
    />
  );

  const content = inventoryItems.length > 0 ? (
    <>
      <table className={styles.inventoryTable}>
        <thead>
          <tr>
            <th>Oil Type</th>
            <th>Oil Weight</th>
            <th>
              Quantity (
              {countryCode === 'US' ? 'QT' : 'L'}
              )
            </th>
            <th>{isManager || isTechnician || isTester ? 'Actions' : ''}</th>
          </tr>
        </thead>
        <tbody>
          {inventoryItems.map((item: OilItem) => {
            const quantity = calculateOilByCountry(
              String(countryCode),
              item.quantity,
            );
            return (
              <tr key={item.id}>
                <td>{item.oil.type}</td>
                <td>{item.oil.weight}</td>
                <td>{quantity}</td>
                <td>
                  {(isManager || isTechnician || isTester) && (
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                      variant="secondary"
                      onClick={
                      () => openIncreaseInventoryOilModal(
                        String(item.id),
                        item.oil.type,
                        item.oil.weight,
                      )
}
                    >
                      Restock
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={
                      () => openDeleteModal(
                        String(item.id),
                        item.oil.type,
                        item.oil.weight,
                      )
}
                    >
                      Delete
                    </Button>
                  </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {currentCount < total && (
        <div className={styles.loadMoreButtonContainer}>
          <Button
            variant="secondary"
            onClick={handleLoadMore}
            className={styles.loadMoreButton}
          >
            Load more
          </Button>
        </div>
      )}
    </>
  ) : (
    <EmptyList msg={emptyListMsg} />
  );

  return (
    <div className={styles.itemsList}>
      <div className={styles.searchDiv}>
        <div className={styles.searchBar}>
          <div className={styles.magnifyingGlass}>
            <Search />
          </div>
          <TextInput
            placeholder="Search Oil by Type or Weight"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.currentTarget.value);
              setFilter(e.currentTarget.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </div>
        <Button
          variant="primary"
          onClick={handleSearch}
          className={styles.searchButton}
        >
          Search
        </Button>
        {(isManager || isTechnician || isTester) && (
          <Button
            variant="primary"
            className={styles.searchButton}
            onClick={() => setAddOilModalOpen(true)}
          >
            Add Oil
          </Button>
        )}
        {addOilModalOpen && <AddOilModal />}
        {errorModal && <CompleteErrorModal />}
        {increaseInventoryOilModalOpen && <IncreaseInventoryOilModal />}
        {deleteModalOpen && (
        <DeleteInventoryOilModal
          itemId={selectedInventoryOilItemId}
          itemName={selectedOilName}
          oilWeight={selectedOilWeight}
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onDeleteSuccess={handleDeleteSuccess}
          setErrorModal={setErrorModal}
        />
        )}
      </div>
      {loading ? <p className={styles.loading}>Loading...</p> : content}
    </div>
  );
}
