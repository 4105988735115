import { useEffect, useState } from 'react';
import {
  CancelWorkOrderModalProps,
} from '../interfaces/Components';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import CancelWorkOrder from './CancelWorkOrder';

export default function CancelWorkOrderContainer({
  workOrderDetails,
  onClose,
  onSubmit,
}: CancelWorkOrderModalProps) {
  const [cancelReason, setCancelReason] = useState<string>('');
  const [
    proofOfCancelLocation,
    setProofOfCancelLocation,
  ] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    if (workOrderDetails?.notes) {
      setNotes(workOrderDetails?.notes);
    } else {
      setNotes('');
    }
  }, [workOrderDetails]);

  const handleSubmit = () => {
    onSubmit(
      cancelReason,
      proofOfCancelLocation,
      notes,
    );
    onClose();
  };

  return (
    <div className={styles.container}>
      {workOrderDetails && (
      <CancelWorkOrder
        proofOfCancelLocation={proofOfCancelLocation}
        setProofOfCancelLocation={setProofOfCancelLocation}
        cancelReason={cancelReason}
        setCancelReason={setCancelReason}
        notes={notes}
        setNotes={setNotes}
        onClose={onClose}
        onClick={handleSubmit}
      />
      )}
    </div>

  );
}
